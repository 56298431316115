import { isNumber, stringToNumber } from "../../../utils";
import { AuthFetch } from "../../../routes";

const SubmitCalculationHelpers = ({ 
    soilTable, 
    calculations,
    results,
    setAlert,  
    setLoading  
}) => {

    const onSubmitCalculation = () => {

        const calculationToSend = Object.fromEntries(Object.entries(calculations.state)
            .filter((ele,_,arr) => arr.find(ele => ele[0] === 'sheetPileList')[1].value ? !['baseArea', 'perimeter'].includes(ele[0]) : !['sheetPileType'].includes(ele[0]))
            .filter((ele) => ele[0] === 'layersName' ? false : true)
            .filter((ele) => ele[0] !== 'cptLevel')
        );
        const soilTableToSend = soilTable.state.map(element => {
            return Object.fromEntries(Object.entries(element).filter(ele => !['sigm','sigmSum','nk','cohesiveType', 'zSoil', 'cd'].includes(ele[0])));
        });
        const isValidCalculations = Object.entries(calculationToSend).every(([key, value]) => value.isValid);
        const isValidSoilTableState = soilTableToSend.every(ele => Object.entries(ele).every(([key,value]) => {
            if(key === 'layerNo'){
                return isNumber(stringToNumber(value.value))
            } else if(key === 'layerIndex'){
                return isNumber(stringToNumber(value.value))
            } else {
                return value.isValid
            }
        }));

        if(isValidSoilTableState && isValidCalculations){
            setLoading({ name: 'calculations', loading: true });
            AuthFetch({
                url: '/api/sheetpile/cpt/calculations/submit', 
                method: 'POST',
                body: {
                    soilTable: soilTableToSend.map(ele => Object.entries(ele).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{})),
                    calculations: Object.entries(calculationToSend).reduce((acc, [key, value]) => {
                        return {
                            ...acc, 
                            [key]: value.value
                        }
                    },{}),
                }
            }).then(response => {
                setLoading({ name: 'calculations', loading: false });
                if(response.success){
                    results.updateCurrentSheetPileLength(response.responseData.calculations.sheetPileLength);
                    results.updateState({
                        isResultsActual: true,
                        ...response.responseData
                    });
                    results.updateCurrentCapacity(response.responseData.currentResult)
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                }
            })
        } else { 
            const invalidSoilState = soilTable.state.map(ele => 
                Object.entries(ele).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }),{}));

            !isValidSoilTableState && setAlert({ error: true, message: 'Nieprawidłowe parametry podłoża gruntowego' });
            !isValidCalculations && setAlert({ error: true, message: 'Nieprawidłowe parametry ścianki szczelnej' });

            soilTable.updateState(state => state.map((element, index) => {
                return {
                    ...element,
                    ...invalidSoilState[index]
                }
            }));
            calculations.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(calculationToSend).reduce((acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{})
                }
            });
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers