import { useState } from 'react';

const capacityChartTypes = [
    {
        type: 0,
        name: 'Długość grodzic',
    },
    {
        type: 1,
        name: 'Obliczeniowa nośność ścianki szczelnej na wciskanie',
    },
    {
        type: 2,
        name: 'Obliczeniowa nośność ścianki szczelnej na wciskanie na mb',
    },
];

const SheetpileCptService = () => {
    const [settings, setSettings] = useState({
        showVertical: true,
        showHorizontal: true,
        showLegend: true,
        showValues: false,
        showDescription: true,
        showValue: true,
        rotateDescriptionX: false,
    });
    const [capacityType, setCapacityType] = useState(0);
    const [capacityName, setCapacityName] = useState('Długość grodzic');
    const [chartNames, setChartNames] = useState({
        0: {
            title: 'Rozkład długości grodzic wzdłuż profili geologicznych',
            axisY: 'Długość grodzic [m]',
            axisX: 'Profile geologiczne',
            legend: '- długość grodzic',
        },
        1: {
            title: 'Nośność pionowa ścianki szczelnej na wciskanie',
            axisY: 'Nośność ścianki szczelnej [kN]',
            axisX: 'Profile geologiczne',
            legend: '- nośność ścianki szczelnej',
        },
        2: {
            title: 'Nośność pionowa ścianki szczelnej na wciskanie na 1mb',
            axisY: 'Nośność ścianki szczelnej [kN/mb]',
            axisX: 'Profile geologiczne',
            legend: '- nośność ścianki szczelnej',
        },
    });

    const onChangeChartTitles = (e = {}, type) => {
        if (e.target) {
            const { name, value } = e.target;

            setChartNames((state) => ({
                ...state,
                [type]: {
                    ...state[type],
                    [name]: value,
                },
            }));
        }
    };
    const onToggleChartType = (e) => {
        const currentSelectProject = capacityChartTypes.find(
            (ele) => ele.name === e.target.value,
        );

        if (currentSelectProject) {
            setCapacityType(currentSelectProject.type);
            setCapacityName(currentSelectProject.name);
        }
    };
    const onChangeSettings = (e) => {
        e.preventDefault();
        e.persist();

        const name = e.currentTarget.getAttribute('name');

        setSettings((state) => ({ ...state, [name]: !state[name] }));
    };

    const sheetpileCptCapacity = {
        state: {
            chartNames,
            capacityType,
            capacityName,
            capacityChartTypes,

            settings,
        },
        onChangeChartTitles,
        onToggleChartType,
        onChangeSettings,
    };

    return {
        sheetpileCptCapacity,
    };
};

export default SheetpileCptService
