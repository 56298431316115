import { Fragment, useContext } from 'react';
import { sheetPileCptContext } from '../../context/SheetPileCpt';
import styles from './index.module.css';
import { MainAlertAndLoading } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from '../../utils';
import { Col, Row } from 'react-bootstrap';

import ProjectTitle from '../CptPile/model/ProjectTitle';
import CptChart from '../CptPile/model/CptChart';
import SoilTable from './model/SoilTable';

import Calculations from './model/Calculations';
import SubmitCalculations from '../SubmitCalculations';

const SheetPileCpt = () => {
    const {
        globalAlerts = {},
        globalLoading = {},
        projectTitle={},
        soilTable={},
        calculationState={},
        saveSoilProfile={},
        editSoilProfile={},
        defaultSoilLayer={},
        submitCalculation={}
    } = useContext(sheetPileCptContext);

    return (
        <Fragment>
            <section className={styles.section}>
                <Row>
                    <ProjectTitle {...projectTitle} />
                </Row>
            </section>
            <section className={styles.section}>
                <Row>
                    <SoilTable 
                        {...soilTable} 
                        dataToPaginate={soilTable.state}
                        defaultSoilLayer={defaultSoilLayer}
                        saveSoilProfile={saveSoilProfile}
                        editSoilProfile={editSoilProfile}
                        globalLoading={globalLoading.state}
                    />
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12} sm={6}>
                    {
                        (Array.isArray(soilTable.state) && soilTable.state.length > 0 && soilTable.state.some(ele => {
                            return isNumber(ele.qc.value) && isNumber(ele.z.value)
                        }))
                        ?
                            <CptChart
                                chartTitle="Wykres sondowania statycznego CPT"
                                yLabel="Głębokość Z [m.p.p.t]"
                                xLabel="Opór stożka sondy CPT - qc [MPa]"
                                dataToChart={soilTable.state.map(state => {
                                    return {
                                        qc: state.qc.value,
                                        z: state.z.value,
                                        soil: state.soilType.value,
                                    }
                                })}
                            />
                        :
                        <div className={styles.banWrapper}>
                            <FontAwesomeIcon icon={faBan} />
                            <p>Uzupełnij parametry gruntu</p>
                        </div>
                    }
                    </Col>
                    <Col xs={12} sm={6}>
                        <Calculations
                            isChartData={soilTable.state.some(ele => ele.cd && ele.cd.value === false)}
                            updateSoilTable={soilTable.updateState}
                            { ...calculationState }
                        />
                    </Col>                
                </Row>
            </section>
            <section>
                <Row>
                    <Col xs={12}>
                        <SubmitCalculations {...submitCalculation} />
                    </Col>
                </Row>
            </section>
            <MainAlertAndLoading
                alerts={globalAlerts.state.alerts}
                updateAlerts={globalAlerts.updateState}
                loadings={globalLoading.state}
            />
        </Fragment>
    );
};

export default SheetPileCpt;
