import React from 'react';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import logoImg from '../../assets/Logo.png'
import { Link } from 'react-router-dom';

const Container = styled.div` 
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; 
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    text-align: center;

    @media (min-width: 576px)
    { 
        max-width: 540px;
    }
    @media (min-width: 768px)
    {
        max-width: 720px;
    }
    @media (min-width: 992px)
    {
        max-width: 960px;
    }
    @media (min-width: 1200px)
    {
        max-width: 1160px;
    }
`
const ImageWrapper = styled.div`
    text-align: center;
    margin: 50px 0;
    padding: 0 20px;
    & img {
        width: 250px
    }
`;
const WrapperA = styled.div`
    text-align: center;

    & a {
        display: block;
        color: #000;
        margin: 25px 0;
        font-size: 16px;

        &:hover {
            text-decoration: underline !important;
        }
    }
`
const alertHOC = (Component) => {
 
    return (props) => {
        const { open, message, renderErrorMessage, logo=false, fallbackRoute=false } = props;
        return (
            renderErrorMessage
                ?
                    !open ? 
                    <Component {...props}/> : 
                    <Container>
                        {
                            logo &&
                            <ImageWrapper>
                                <img src={logoImg} alt='Company logo'/>
                            </ImageWrapper>
                        }
                        <div className="alert-message">
                            <Alert variant="danger" show={open} className="show-hide-message">{message}</Alert>
                        </div>
                        {
                            fallbackRoute && 
                            <WrapperA>
                                <Link to={fallbackRoute}>Powrót do strony logowania</Link>
                            </WrapperA>
                        }
                    </Container>
                : 
                    !open && <Component {...props} /> 
        )
    }
} 
export default alertHOC; 