import React, { useRef, useEffect, useState } from 'react';
import ActivationContent from './ActivationContent';
import config from '../../config/config';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AuthFetch from '../../routes/AuthFetch';
import { useIsMountedState } from '../../hooks';
import { useParams, useNavigate } from 'react-router-dom';

const Activation = () => {
    const [messageState, setMessageState] = useState({
        message: '',
        open: false,
        success: true,
    });
    const { id } = useParams();

    const [loadingState, setLoadingState] = useState({
        isLoaded: false,
        name: 'Aktywacja konta...',
    });
    const [expired, setExpired] = useState(false);
    const timeoutRef = useRef(null);
    const { routes } = config;
    const isMountedState = useIsMountedState();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Aktywacja konta`;

        return () => {
            document.title = '';
        };
    }, []);

    useDeepCompareEffect(() => {
        AuthFetch({
            method: 'PUT',
            url: `/api/auth/account/activate/${id}`,
        })
        .then((response) => {
            if (isMountedState.current) {
                setLoadingState((state) => ({
                    ...state,
                    isLoaded: true,
                }));

                if (response.status === 200) {
                    timeoutRef.current = setTimeout(() => {
                        navigate(routes.dashboard.logIn);
                    }, 2000);
                    setMessageState({
                        message: 'Aktywacja konta przebiegła prawidłowo!',
                        success: response.success,
                        open: true,
                    });
                } else {
                    if (response.responseData.expired) {
                        setExpired(true);
                        setMessageState({
                            message: 'Link aktywacyjny stracił ważność',
                            success: response.success,
                            open: true,
                        });
                    }
                    setMessageState({
                        message: 'Wystąpił błąd podczas aktywacji konta',
                        success: response.success,
                        open: true,
                    });
                }
            }
        })
        .catch((e) => {
            if (isMountedState.current) {
                setLoadingState((state) => ({
                    ...state,
                    isLoaded: true,
                }));
                setMessageState({
                    message: 'Wystąpił błąd podczas aktywacji konta',
                    success: false,
                    open: true,
                });
            }
        });

        return () => clearTimeout(timeoutRef.current);
    }, [isMountedState, routes.dashboard.logIn]);

    return (
        <ActivationContent
            {...messageState}
            expired={expired}
            isLoaded={loadingState.isLoaded}
            loadingName={loadingState.name}
        />
    );
};

export default Activation;
