import React, { createContext } from 'react';
import { 
    ProjectsListService,
    ProfilesListService,
    GlobalAlertService,
    GlobalLoadingService,
    DefaultSoilService,
    FileNameToSave,
    CalculationListService,
    PrintResultsService
} from '../../services/Projects'; 
  
const projectsContext = createContext({});

const ProjectsProvider = ({ children }) => {
    const { fileNameToSave }            = FileNameToSave();
    const { globalAlerts }              = GlobalAlertService();
    const { globalLoading }             = GlobalLoadingService(); 
    const { createProject }             = ProjectsListService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onLoadProjectName: fileNameToSave.onLoad,
        fetch: {
            create: { 
                url: '/api/projects/create', 
                method: 'POST'
            },
            delete: {
                url: '/api/projects', 
                method: 'DELETE'
            },
            loadCalculations: {
                url: '/api/projects/calculations',
                method: 'GET'
            }
        },        
    });  
    const { createProfile }             = ProfilesListService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        currentProject: createProject.state.currentProject,
        updateCurrentProject: createProject.updateCurrentProject,
        updateAllCalculationsWitoutProfile: createProject.updateAllCalculationsWitoutProfile,
        fetch: {
            create: {
                url: '/api/soils/parameters/edit',
                method: 'POST' 
            },
            delete: {
                url: '/api/soils/parameters/edit',
                method: 'DELETE'
            }, 
            deleteCpt: {
                url: '/api/soils/cpt/parameters/edit',
                method: 'DELETE'
            }, 
            calculations: {
                url: '/api/projects/list/names/profiles/calculations',
                method: 'POST'
            }
        }
    });

    const { calculations }              = CalculationListService({
        setAlert: globalAlerts.addAlert,
        updateAllCalculationsWitoutProfile: createProject.updateAllCalculationsWitoutProfile,
        updateCalculationsInProfile: createProfile.updateCalculationsInProfile,
        projectId: createProject.state.currentProject.id,
        fetch: {
            delete: {
                url: '/api/projects/calculations',
                method: 'DELETE'
            },
        } 
    });
    const { printResults } = PrintResultsService({
        calculations:   [...createProject.state.allCalculationsWitoutProfile, ...createProfile.state.calculationsInProfile.map(ele => ele.calculations).flat(1)].filter(ele => ele.results.isResultsActive && ele.results.isResultsActual)
    });

    const { defaultSoilLayer }      = DefaultSoilService(createProfile.updateSoilState);
    
    return ( 
        <projectsContext.Provider   
            value={{ 
                globalAlerts,
                globalLoading,
                createProject,
                createProfile,
                calculations,
                defaultSoilLayer,
                fileNameToSave,
                printResults
            }}
        >
            {children}
        </projectsContext.Provider>
    )
}

export { projectsContext }
export default ProjectsProvider