import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import { findClosest, isNumber, roundNumber, setSoilColorQc } from '../../../../../../utils';
import { SectionTitle } from '../../../../../../components';
import SoilLayer from './SoilLayer';
import styles from './index.module.css';
import round from '../../../../../../utils/roundNumber';
import { Col, Row } from 'react-bootstrap';
import { useWindowSize } from '../../../../../../hooks';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const SoilProfile = ({
    openSidebar,
    currentPileLengthByUser,
    groundLevel,
    sheetPileHeadSpot,
    sheetPileLength,
    sheetPileList,
    sheetPileNumbers,
    sheetPileType,
    baseArea,
    perimeter,
    technology,
    yt,
    results=[],
    soilTable=[],
    onChangeCurrentSheetPileLength, 
    onChangeCurrentCapacity
}) => {
    const soilProfileRef = useRef(null);
    const resultsRef = useRef(null);
    const [soilLayer, setSoilLayer] = useState([]);
    const [resultsWidth, setResultsWidth] = useState(0);
    const [soilProfileHeight, setSoilProfileHeight] = useState(0);
    const [profilePileLength, setProfilePileLength] = useState('');
    const [profilePileHeadSpot, setProfilePileHeadSpot] = useState('');
    const [axisNtScale, setAxisNtScale] = useState([]);
    const [maxNt, setMaxNt] = useState('');
    const [maxHeight, setMaxHeight] = useState(0);

    const [currentNt, setCurrentNt] = useState('');
    const [pileBaseSpot, setPileBaseSpot] = useState('');
    const [currentPileLength, setCurrentPileLength] = useState(
        currentPileLengthByUser
    );
    const [currentCapacity, setCurrentCapacity] = useState({});
    const windowSize = useWindowSize();
    const soilProfileHeights = results.map((ele) => ele.z);
    const wheelTimeout = useRef(null);

    useEffect(() => {
        setCurrentPileLength(currentPileLengthByUser);
    },[currentPileLengthByUser])

    useEffect(() => {
        if (resultsRef.current) {
            setResultsWidth(resultsRef.current.offsetWidth*0.9);
        }
    }, [
        openSidebar,
        windowSize.width,
    ]);

    useEffect(() => {
        onChangeCurrentSheetPileLength(currentPileLength);
    }, [
        onChangeCurrentSheetPileLength,
        currentPileLength,
    ]);

    useDeepCompareEffect(() => {
        setMaxHeight(Math.max(...soilTable.map(ele => ele.z)));
    }, [soilTable]);

    useDeepCompareEffect(() => {
        if(Object.keys(currentCapacity).length > 0){
            onChangeCurrentCapacity({
                ...currentCapacity,
            });
        }
    },[
        onChangeCurrentCapacity,
        currentCapacity,
    ]);

    useDeepCompareEffect(() => {
        if (soilProfileRef.current) {
            const offsetHeight = soilProfileRef.current.offsetHeight;
            const maxHeight = Math.max(...soilTable.map(ele => ele.z));
            const layers = soilTable.map((ele, i, arr) => {
                return (
                    <SoilLayer
                        key={i}
                        backgroundColor={setSoilColorQc({
                            type: ele.soilType,
                            qc: ele.qc,
                        })}
                        height={
                            maxHeight === 0
                                ? 0
                                : (offsetHeight *
                                      (i === arr.length - 1
                                          ? ele.absHeight
                                          : ele.absHeight)) /
                                  maxHeight
                        }
                    />
                );
            });
            setSoilLayer(layers);
            setSoilProfileHeight(offsetHeight);
        }
    }, [soilTable, soilProfileHeights, pileBaseSpot]);

    useDeepCompareEffect(() => {
        const nt = Math.max(...results.map((ele) =>  ele.Rcd ));
        const maxAxisNt = round(nt - (nt % 5), 0);

        const initPileLength =
            isNumber(currentPileLength) && currentPileLength >= 0
                ? currentPileLength
                : isNumber(sheetPileLength) && sheetPileLength > 0
                ? sheetPileLength
                : roundNumber(maxHeight - sheetPileHeadSpot - 1,2);

        const initNt =
            results.find(
                (ele) => roundNumber(ele.z,2) === roundNumber(initPileLength + sheetPileHeadSpot,2),
            ) || {};

        setCurrentCapacity(initNt);

        setAxisNtScale(
            Array(5)
            .fill(maxAxisNt / 5)
            .reduce((acc, ele, idx) => [...acc, ele * (idx + 1)], []),
        );
        
        setMaxNt(nt);

        setPileBaseSpot((state) =>
            isNumber(state) && state > sheetPileHeadSpot
                ? state
                : roundNumber(initPileLength + sheetPileHeadSpot,2),
        );

        setCurrentNt(() => initNt.Rcd);

        if (soilProfileRef.current) {
            setProfilePileHeadSpot(
                maxHeight === 0
                    ? 0
                    : (soilProfileHeight * sheetPileHeadSpot) / maxHeight,
            );
            setProfilePileLength(maxHeight === 0 ? 0 : (soilProfileHeight * initPileLength) / maxHeight);
        }
    }, [
        maxHeight,
        soilProfileHeight,
        currentPileLength,
        currentCapacity,
        sheetPileHeadSpot,
        sheetPileLength,
        results,
    ]);

    const onChangePileLength = (e) => {
        e.stopPropagation();

        const profileSpots = results.map((ele) => ele.z);
        const clientRect = e.currentTarget.getClientRects()[0];
        const baseSpot = ((clientRect.height - clientRect.bottom + e.clientY) / clientRect.height) * maxHeight;
        const closestSpot = findClosest(profileSpots, baseSpot);
        const closestResult = results.find((ele) => ele.z === roundNumber(closestSpot,2));

        setCurrentNt((state) => closestResult ? closestResult.Rcd_current : state);
        setCurrentCapacity(closestResult);
        setPileBaseSpot((state) =>
            closestSpot > sheetPileHeadSpot ? closestSpot : state,
        );
        setCurrentPileLength((state) =>
            closestSpot > sheetPileHeadSpot ? closestSpot - sheetPileHeadSpot : state,
        );
        setProfilePileLength((state) =>
            closestSpot > sheetPileHeadSpot
                ? (clientRect.height * (closestSpot - sheetPileHeadSpot)) / maxHeight
                : state,
        );
    };

    useEffect(() => {
        const cancelWheel = e => wheelTimeout.current && e.preventDefault();
        document.body.addEventListener('wheel', cancelWheel, { passive: false });
        return () => document.body.removeEventListener('wheel', cancelWheel);
    },[]);

    const onScrollPileLengthOnChart = (e) => {  
        clearTimeout(wheelTimeout.current);

        wheelTimeout.current = setTimeout(() => {
            wheelTimeout.current = false;
            const closestResultIdx = results.findIndex((ele) => ele.z === roundNumber(currentPileLength + profilePileHeadSpot,2));

            if(e.deltaY < 0){
                const newPileLength = results[closestResultIdx - 1] ? results[closestResultIdx - 1].z - profilePileHeadSpot : currentPileLength;
                setCurrentNt((state) => results[closestResultIdx - 1] ? results[closestResultIdx - 1].Rcd_current : state);
                setCurrentCapacity(state => results[closestResultIdx - 1] ? results[closestResultIdx - 1] : state);
                setPileBaseSpot((state) => results[closestResultIdx - 1] ? results[closestResultIdx - 1].z : state);
                setCurrentPileLength(newPileLength);
            } else if(e.deltaY > 0){
                const newPileLength = results[closestResultIdx + 1] ? results[closestResultIdx + 1].z - profilePileHeadSpot : currentPileLength;
                setCurrentNt((state) => results[closestResultIdx + 1] ? results[closestResultIdx + 1].Rcd_current : state);
                setCurrentCapacity(state => results[closestResultIdx + 1] ? results[closestResultIdx + 1] : state);
                setPileBaseSpot((state) => results[closestResultIdx + 1] ? results[closestResultIdx + 1].z : state);
                setCurrentPileLength(newPileLength);
            }
        },0);
    };

    return (
        <Fragment>
            <Row>
                <Col xs={12} sm={6}>
                    <SectionTitle
                        className={styles.title}
                        title={'Nośność pionowa grodzic na wciskanie na podstawie wyników badań sondą CPT'}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {
                        <div className={styles.wrapper} ref={soilProfileRef} onClick={onChangePileLength} onWheel={onScrollPileLengthOnChart}>
                            <div className={styles.pileWrapperCapacityView}
                                style={{
                                    height: `${profilePileLength}px`,
                                    top: `${profilePileHeadSpot}px`,
                                }}
                            >       
                                <div className={styles.pileCapacityView}>                               
                                    <div className={styles.sheetPileLength}>
                                        <p>
                                            {sheetPileList && `${sheetPileType}`} L ={' '}
                                            {round(currentPileLength, 2)} m
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.spotWrapper}>
                                    <span className={styles.pileSpot}>
                                        {isNumber(sheetPileHeadSpot) ? sheetPileHeadSpot.toFixed(2) : ''}
                                        <span className={styles.spanBlock}>
                                            {(isNumber(sheetPileHeadSpot) && isNumber(groundLevel)) ? `(${(groundLevel - sheetPileHeadSpot).toFixed(2)})`: ''}
                                        </span>
                                    </span>
                                    <span className={styles.pileSpot}> 
                                        {isNumber(pileBaseSpot) ? pileBaseSpot.toFixed(2) : ''}
                                        <span className={styles.spanBlock}>
                                            {(isNumber(pileBaseSpot) && isNumber(groundLevel)) ? `(${(groundLevel - pileBaseSpot).toFixed(2)})`: ''}
                                        </span>
                                    </span>
                                </div>
                                <div className={styles.results} ref={resultsRef}>
                                    <div className={styles.horizontalAxis}>
                                        {axisNtScale.map((ele, idx) => {
                                            return (
                                                <span key={idx} className={styles.axisCoord}
                                                    style={{left: `${(resultsWidth / maxNt)*ele}px`}}>
                                                    <p className={ styles.value }>
                                                        {ele}
                                                    </p>
                                                </span>
                                            );
                                        })}

                                        <span className={styles.caret}>
                                            <p className={styles.axisDescription}>
                                                <Fragment>R<sub>c,d</sub>[kN]</Fragment>
                                            </p>
                                        </span>
                                    </div>
                                    <div className={styles.verticalAxis}>
                                        <span className={styles.verticalCaret}>
                                            <p className={styles.axisVerticalDescription}>Z[m]</p>
                                        </span>
                                    </div>
                                    <div className={styles.currentHorizontalValue} 
                                        style={{ 
                                            top: `${profilePileLength}px`,
                                            width: `${(resultsWidth * currentNt) / maxNt}px`,
                                        }}
                                    >
                                        <div className={styles.line}></div>
                                    </div>
                                    <div className={styles.currentVerticalValue}
                                        style={{
                                            height: `${profilePileLength}px`,
                                            left: `${
                                                (resultsWidth * currentNt) /
                                                maxNt
                                            }px`,
                                        }}>
                                            <div className={styles.lineVertical}></div>
                                    </div>
                                    <svg className={styles.svgWrapper}>
                                        {results.map((ele, i, a) => {
                                            return (
                                                <Fragment key={i}>
                                                {
                                                    i < (a.length - 1)
                                                    &&
                                                    <Fragment key={i}>
                                                        <polyline 
                                                            className={styles.svgLine} 
                                                            points={`
                                                                ${(resultsWidth*ele.Rcd) / maxNt}, ${(soilProfileHeight *(ele.z - sheetPileHeadSpot)) /maxHeight} 
                                                                ${(resultsWidth*a[i+1].Rcd) / maxNt}, ${(soilProfileHeight *(a[i+1].z - sheetPileHeadSpot)) /maxHeight}`}
                                                        ></polyline>
                                                    </Fragment>
                                                    }
                                                </Fragment>
                                            )
                                        })}
                                    </svg>
                                    {results.map((ele, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <div className={`${styles.capacityPoint} ${styles.validDepth}`}
                                                    style={{
                                                        top: `${(soilProfileHeight *(ele.z - sheetPileHeadSpot)) /maxHeight}px`,
                                                        left: `${(ele.Rcd > 0) ? (resultsWidth*ele.Rcd) / maxNt : 0}px`
                                                    }}>
                                                        <FontAwesomeIcon
                                                            icon={faCheckCircle}
                                                        />
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>
                                {[...soilLayer]}
                            </div>
                        </div>
                    }
                </Col>
                <Col xs={12} sm={6}>
                    <div className={styles.resultsOverview}>
                        <SectionTitle
                            className={styles.title}
                            title="Wyniki"
                        />
                        <div className={styles.sticky}>
                            {
                                sheetPileList &&
                                <p>Rodzaj ścianki szczelnej [-] -{' '}
                                    <b>{sheetPileType}</b>
                                </p>
                            }
                            {
                                !sheetPileList && 
                                <Fragment>
                                    <p>Powierzchnia podstawy [A] -{' '}
                                        <b>{baseArea} cm<sup>2</sup></b>
                                    </p>
                                    <p>Pobocznica zestawu grodzic [U] -{' '}
                                        <b>{perimeter} cm</b>
                                    </p>
                                </Fragment>
                            }
                            <p>Technologia pogrążania grodzic [-] -{' '}
                                <b>{technology}</b>
                            </p>
                            <p>
                                Długość ścianki szczelnej w gruncie [L] -{' '}
                                <b>
                                    {isNumber(currentPileLength)
                                        ? round(currentPileLength, 2).toFixed(2)
                                        : ''}{' '}
                                    m
                                </b>
                            </p>
                            {
                                sheetPileNumbers > 1 &&
                                <Fragment>
                                    <p>
                                        <Fragment>
                                            Nośność podstawy jednego zestawu [R<sub>b,single,d</sub>] -{' '}
                                            <b>
                                                {isNumber(currentCapacity.Rbk_single/yt)
                                                    ? roundNumber(currentCapacity.Rbk_single/yt, 2)
                                                    : ' '} kN
                                            </b>
                                        </Fragment>
                                    </p>
                                    <p>
                                        Nośność pobocznicy jednego zestawu [R<sub>s,single,d</sub>] -{' '}
                                        <b>
                                            <Fragment>
                                                {isNumber(currentCapacity.Rsk_single/yt)
                                                    ? roundNumber(currentCapacity.Rsk_single/yt, 2)
                                                    : ''}
                                                kN                                                
                                            </Fragment>
                                        </b>
                                    </p>
                                    <p>
                                        Nośność ścianki szczelnej na wciskanie jednego zestawu [R<sub>c,single, d</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rcd_single)
                                                ? round(currentCapacity.Rcd_single, 2)
                                                : ''}{' '}
                                            kN
                                        </b>
                                    </p>
                                </Fragment>
                            }
                            <p>
                                <Fragment>
                                    Nośność podstawy [R<sub>b,d</sub>] -{' '}
                                    <b>
                                        {isNumber(currentCapacity.Rbk)
                                            ? roundNumber(currentCapacity.Rbk/yt, 2)
                                            : ' '} kN
                                    </b>
                                </Fragment>
                            </p>
                            <p>
                                Nośność pobocznicy [R<sub>s,d</sub>] -{' '}
                                <b>
                                    <Fragment>
                                        {isNumber(currentCapacity.Rsk)
                                            ? roundNumber(currentCapacity.Rsk/yt, 2)
                                            : ''}
                                        kN                                                
                                    </Fragment>
                                </b>
                            </p>

                            {
                                sheetPileList &&
                                <p>
                                    Nośność ścianki szczelnej na wciskanie jako ciągłej ściany* [R<sub>c,d</sub>] -{' '}
                                        <b>
                                            {isNumber(currentCapacity.Rcd_meter)
                                                ? round(currentCapacity.Rcd_meter, 2)
                                                : ''}{' '}
                                            kN/mb 
                                        </b>
                                </p>                                
                            }
                            <p>
                                <u>
                                    Nośność ścianki szczelnej na wciskanie [R<sub>c,d</sub>] -{' '}
                                    <b>
                                        {isNumber(currentCapacity.Rcd)
                                            ? round(currentCapacity.Rcd, 2)
                                            : ''}{' '}
                                        kN
                                    </b>
                                </u>
                            </p>
                            {
                                sheetPileList &&
                                <p>* Nośność ściany na metr bieżący wyznaczono z założeniem, że obliczenia dotyczą konstrukcji ciągłej. W przypadku innych typów konstrukcji np. pale skrzynkowe, ścianka Jagged-wall itp. wartość tę należy zignorować.</p>
                            }
                            <div className={styles.summary}>
                                <Row className={styles.row}>
                                    <Col xs={12} className={styles.col}>
                                        <h3>PODSUMOWANIE WYNIKÓW:</h3>
                                    </Col>
                                </Row>
                                <Row className={styles.row}>
                                    <Col xs={4} className={styles.col}>
                                        <p>
                                            R<sub>c,d</sub>-{' '}
                                            <b>
                                                {isNumber(currentCapacity.Rcd)
                                                    ? round(currentCapacity.Rcd, 2)
                                                    : ''}{' '}
                                                kN
                                            </b>
                                        </p>
                                    </Col>
                                    <Col xs={4} className={styles.col}>
                                        <p>
                                            R<sub>b,d</sub>-{' '}
                                            <b>
                                                <Fragment>
                                                    {isNumber(currentCapacity.Rbk)
                                                        ? roundNumber(currentCapacity.Rbk/yt, 2)
                                                        : ' '}
                                                    kN
                                                </Fragment>
                                            </b>
                                        </p>
                                    </Col>                                        
                                    <Col xs={4} className={styles.col}>
                                        <p>
                                            R<sub>s,d</sub>-{' '}
                                            <b>
                                                <Fragment>
                                                    {isNumber(currentCapacity.Rsk)
                                                        ? roundNumber(currentCapacity.Rsk/yt, 2)
                                                        : ' '}
                                                    kN
                                                </Fragment>
                                            </b>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>                
            </Row>
        </Fragment>
    );
};

export default React.memo(
    SoilProfile,
    (prev, next) => stringify(prev) === stringify(next),
);
