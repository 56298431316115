import React, { Fragment, useContext } from 'react';
import {
    Button,
    InputField,
    RadioInput1,
    SectionTitle,
    SelectField,
} from '../../../../components';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.css';
import case7_4 from '../../../../assets/Waling_7.4_light.jpg';
import case7_5 from '../../../../assets/Waling_7.5_light.jpg';
import case7_5_1 from '../../../../assets/Waling_7.5.1_light.jpg';
import case7_6 from '../../../../assets/Waling_7.6_light.jpg';

import case7_4_dark from '../../../../assets/Waling_7.4_dark.jpg';
import case7_5_dark from '../../../../assets/Waling_7.5_dark.jpg';
import case7_5_1_dark from '../../../../assets/Waling_7.5.1_dark.jpg';
import case7_6_dark from '../../../../assets/Waling_7.6_dark.jpg';

import waling from '../../../../assets/Waling/waling_ceo.JPG';
import plate_cross from '../../../../assets/Waling/plate_cross.JPG';
import plate_cross_1 from '../../../../assets/Waling/plate_cross_1.JPG';
import plate_cross_2 from '../../../../assets/Waling/plate_cross_2.JPG';
import plate_cross_3 from '../../../../assets/Waling/plate_cross_3.JPG';
import plate_cross_4 from '../../../../assets/Waling/plate_cross_4.JPG';
import plate_cross_5 from '../../../../assets/Waling/plate_cross_5.JPG';
import plate_cross_6 from '../../../../assets/Waling/plate_cross_6.JPG';

import waling_dark from '../../../../assets/Waling/waling_ceo_dark.JPG';
import plate_cross_dark from '../../../../assets/Waling/plate_cross_dark.JPG';
import plate_cross_1_dark from '../../../../assets/Waling/plate_cross_1_dark.JPG';
import plate_cross_2_dark from '../../../../assets/Waling/plate_cross_2_dark.JPG';
import plate_cross_3_dark from '../../../../assets/Waling/plate_cross_3_dark.JPG';
import plate_cross_4_dark from '../../../../assets/Waling/plate_cross_4_dark.JPG';
import plate_cross_5_dark from '../../../../assets/Waling/plate_cross_5_dark.JPG';
import plate_cross_6_dark from '../../../../assets/Waling/plate_cross_6_dark.JPG';

import { themeContext } from '../../../../context/Theme';

const Calculations = ({ 
    state, 
    sheetPilesWaling, 
    ceo, 
    steelClasses, 
    plateSteelGrades, 
    onChange, 
    onChangeType, 
    onBlur, 
    onKeyDown, 
    onChangeView, 
    viewType,

    children
}) => {

    const { mode } = useContext(themeContext);

    return (
        <Fragment>
            <div className={styles.wrapper}>
                <SectionTitle title="Schemat pracy układu" />
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} sm={3} className={styles.columnSpace}>
                                <img 
                                    alt='Waling type 1'
                                    className={`${styles.image} ${state.walingType_1.value ? styles.selectedImage : ''}`} 
                                    src={mode === 'light' ? case7_6 : case7_6_dark} 
                                    onClick={onChangeType}
                                    name="walingType_1"
                                />
                                {
                                    state.walingType_1.value && <p className={styles.description}>Zakotwienie z belką od strony wolnej</p>
                                }
                            </Col>
                            <Col xs={12} sm={3} className={styles.columnSpace}>
                                <img 
                                    alt='Waling type 4'
                                    className={`${styles.image} ${state.walingType_4.value ? styles.selectedImage : ''}`} 
                                    src={mode === 'light' ? case7_4 : case7_4_dark} 
                                    onClick={onChangeType}
                                    name="walingType_4"
                                />
                                {
                                    state.walingType_4.value && <p className={styles.description}>Zakotwienie z belką od strony lądu</p>
                                }
                            </Col>                            
                            <Col xs={12} sm={3} className={styles.columnSpace}>
                                <img 
                                    alt='Waling type 2'
                                    className={`${styles.image} ${state.walingType_2.value ? styles.selectedImage : ''}`} 
                                    src={mode === 'light' ? case7_5 : case7_5_dark} 
                                    onClick={onChangeType}
                                    name="walingType_2"
                                />
                                {
                                    state.walingType_2.value && <p className={styles.description}>Kotwy w grzbiecie ścianki, bez belkowania</p>
                                }
                            </Col>
                            <Col xs={12} sm={3} className={styles.columnSpace}>
                                <img 
                                    alt='Waling type 3'
                                    className={`${styles.image} ${state.walingType_3.value ? styles.selectedImage : ''}`} 
                                    src={mode === 'light' ? case7_5_1 : case7_5_1_dark} 
                                    onClick={onChangeType}
                                    name="walingType_3"
                                />
                                {
                                    state.walingType_3.value && <p className={styles.description}>Kotwy w dolinie ścianki, bez belkowania</p>
                                }
                            </Col>
                        </Row>                        
                    </Col>
                </Row>
                <SectionTitle title="Typ analizy" />
                <Row>
                    <Col xs={12} sm={{ span: 8, offset: 2 }}>
                        <Row>
                            <Col xs={12} sm={6} className={styles.columnSpace}>
                                <RadioInput1
                                    label="Wymiaruj elementy"
                                    name='calcElements'
                                    onChange={onChange}
                                    value={state.calcElements.value}
                                />
                            </Col>
                            <Col xs={12} sm={6} className={styles.columnSpace}>
                                <RadioInput1
                                    label="Sprawdź nośność elementów"
                                    name='checkElements'
                                    onChange={onChange}
                                    value={state.checkElements.value}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4}>
                        {
                            (state.walingType_1.value || state.walingType_4.value) &&
                            <Row>
                                <div className={styles.flex}>
                                    <Button
                                        css={`project-navigation ${viewType === 0 ? 'active' : ''}`}
                                        onClick={() => onChangeView(0)}
                                        title={'Przekrój kleszcza'}
                                    />
                                    <Button
                                        css={`project-navigation ${viewType === 1 ? 'active' : ''}`}
                                        onClick={() => onChangeView(1)}
                                        title={'Widok płyty oporowej'}
                                    />
                                </div>
                            </Row>                            
                        }

                        <Row>
                            {
                                viewType === 0 && (state.walingType_1.value || state.walingType_4.value) &&
                                <Col xs={12} className={styles.columnSpace}>
                                    <img 
                                        alt=''
                                        className={`${styles.image1}`} 
                                        src={mode === 'light' ? waling : waling_dark} 
                                    />
                                </Col>                                
                            }
                            {
                                (!(state.walingType_1.value || state.walingType_4.value) || (viewType === 1 && (state.walingType_1.value || state.walingType_4.value))) &&
                                <Fragment>
                                    {
                                        state.walingType_1.value &&
                                        <Col xs={12} className={styles.columnSpace}>
                                            <div className={styles.center}>
                                                <img 
                                                    alt=''
                                                    className={`${styles.image2}`} 
                                                    src={mode === 'light' ? plate_cross : plate_cross_dark} 
                                                />
                                            </div>
                                        </Col>
                                    }
                                    {
                                        state.walingType_4.value &&
                                        <Col xs={12} className={styles.columnSpace}>
                                            <div className={styles.center}>
                                                <img 
                                                    alt=''
                                                    className={`${styles.image2}`} 
                                                    src={
                                                        mode === 'light' 
                                                        ? state.type.value === 'u' ? plate_cross_2 : plate_cross_3 
                                                        : state.type.value === 'u' ? plate_cross_2_dark : plate_cross_3_dark 
                                                    } 
                                                />
                                            </div>
                                        </Col>
                                    }
                                    {
                                        state.walingType_3.value &&
                                        <Col xs={12} className={styles.columnSpace}>
                                            <div className={styles.center}>
                                                <img 
                                                    alt=''
                                                    className={`${styles.image2}`} 
                                                    src={
                                                        mode === 'light' 
                                                        ? state.type.value === 'u' ? plate_cross_1 : plate_cross_4
                                                        : state.type.value === 'u' ? plate_cross_1_dark : plate_cross_4_dark
                                                    } 
                                                />
                                            </div>
                                        </Col>
                                    }
                                    {
                                        state.walingType_2.value &&
                                        <Col xs={12} className={styles.columnSpace}>
                                            <div className={styles.center}>
                                                <img 
                                                    alt=''
                                                    className={`${styles.image2}`} 
                                                    src={
                                                        mode === 'light' 
                                                        ? state.type.value === 'u' ? plate_cross_5 : plate_cross_6
                                                        : state.type.value === 'u' ? plate_cross_5_dark : plate_cross_6_dark
                                                    } 
                                                />
                                            </div>
                                        </Col>
                                    }
                                </Fragment>
                            }
                        </Row>
                    </Col>
                    <Col xs={12} sm={7}>
                        <SectionTitle title="Parametry ogólne do obliczeń" />
                        <Row>
                            <Col 
                                xs={12}
                                sm={6} 
                                md={(state.walingType_1.value || state.walingType_2.value) ? 6 : state.walingType_4.value ? 6 : 4}
                                className={styles.columnSpace}
                            >
                                <InputField
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                    value={state.anchorForce.value}
                                    isValid={state.anchorForce.isValid}
                                    type="number"
                                    title="Siła od kotwienia [kN/m]"
                                    placeholder={'Siła kotwienia'}
                                    name="anchorForce"

                                    required
                                />
                            </Col>
                            {
                                (state.walingType_1.value || state.walingType_2.value) &&
                                <Fragment>
                                    <Col 
                                        xs={12}
                                        sm={6} 
                                        md={(state.walingType_1.value || state.walingType_2.value) ? 6 : state.walingType_4.value ? 3 : 4}
                                        className={styles.columnSpace}
                                    >
                                        <InputField
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={state.med_sheet.value}
                                            isValid={state.med_sheet.isValid}
                                            type="number"
                                            title="Moment w poziomie kotwienia [kNm/m]"
                                            placeholder={'Moment w poziomie kotwienia'}
                                            name="med_sheet"

                                            required
                                        />
                                    </Col>
                                    <Col 
                                        xs={12}
                                        sm={6} 
                                        md={(state.walingType_1.value || state.walingType_4.value) ? 6 : 4}
                                        className={styles.columnSpace}
                                    >
                                        <InputField
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={state.bb.value}
                                            isValid={state.bb.isValid}
                                            type="number"
                                            title={<Fragment>Współczynnik redukcyjny &beta;<sub>B</sub></Fragment>}
                                            placeholder={'Wsp. redukcyjny'}
                                            name="bb"

                                            required
                                        >
                                            Współczynnik uwzględniający możliwość niepełnego przekazywania sił ścinających w zamkach, którego wartości wynoszą: <br></br>
                                            &beta;<sub>B</sub> = 1 dla grodzic typu Z i potrójnych U <br></br>
                                            &beta;<sub>B</sub> &#8804; 1 dla pojedynczych i podwójnych grodzic typu U

                                        </InputField>
                                    </Col>                                    
                                </Fragment>
                            }

                            <Col 
                                xs={12} 
                                sm={6} 
                                md={(state.walingType_1.value || state.walingType_4.value) ? 6 : 4}
                                className={styles.columnSpace}
                            >
                                <InputField
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                    value={state.space.value}
                                    isValid={state.space.isValid}
                                    type="number"
                                    title="Rozstaw osiowy kotew [m]"
                                    placeholder={'Rozstaw kotew'}
                                    name="space"

                                    required
                                />
                            </Col>
                            <Col 
                                xs={12} 
                                sm={6} 
                                md={(state.walingType_1.value || state.walingType_4.value) ? 6 : 4}
                                className={styles.columnSpace}
                            >
                                <SelectField
                                    title="Rodzaj ścianki szczelnej"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={state.sheetPileType.value}
                                    defaultOption="Wybierz rodzaj ścianki"
                                    isValid={state.sheetPileType.isValid}
                                    options={Object.keys(sheetPilesWaling)}
                                    name="sheetPileType"

                                    required
                                />
                            </Col>
                            {
                                (state.walingType_1.value || state.walingType_4.value) &&
                                <Col xs={12} sm={6} md={6} className={styles.columnSpace}>
                                    <SelectField
                                        title="Klasa stali kleszcza"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={state.steelClass.value}
                                        defaultOption="Wybierz klasę stali"
                                        isValid={state.steelClass.isValid}
                                        options={steelClasses.map(ele => ele.name)}
                                        name="steelClass"

                                        required
                                    />
                                </Col>                                
                            }

                        </Row>
                        {
                        (state.checkElements.value && (state.walingType_1.value || state.walingType_4.value)) &&
                        <Fragment>
                            <SectionTitle title="Parametry kleszcza" />
                            <Row>
                                <Col xs={12} sm={6} className={styles.columnSpace}>
                                    <SelectField
                                        title="Rodzaj ceownika"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={state.ceo.value}
                                        defaultOption="Wybierz rodzaj ceownika"
                                        isValid={state.ceo.isValid}
                                        options={Object.keys(ceo)}
                                        name="ceo"

                                        required
                                    />
                                </Col>
                                <Col xs={12} sm={6} className={styles.columnSpace}>
                                    <InputField
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={state.s.value}
                                            isValid={state.s.isValid}
                                            type="number"
                                            title={<Fragment>Rozstaw ceowników - s [mm]</Fragment>}
                                            placeholder={'Rozstaw ceowników'}
                                            name="s"

                                            required
                                        />
                                </Col>
                            </Row>
                        </Fragment>
                        }
                        <SectionTitle title="Parametry płyty oporowej" />
                        <Row>
                            {
                                (state.checkElements.value) &&
                                <Fragment>
                                    <Col xs={12} sm={4} className={styles.columnSpace}>
                                        <InputField
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={state.width.value}
                                            isValid={state.width.isValid}
                                            type="number"
                                            title={<Fragment>Szerokość płytki - b<sub>w</sub> [mm]</Fragment>}
                                            placeholder={'Szerokość płytki'}
                                            name="width"

                                            required
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} className={styles.columnSpace}>
                                        <InputField
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={state.height.value}
                                            isValid={state.height.isValid}
                                            type="number"
                                            title={<Fragment>Wysokość płytki - h<sub>p</sub> [mm]</Fragment>}
                                            placeholder={'Wysokość płytki'}
                                            name="height"

                                            required
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} className={styles.columnSpace}>
                                        <InputField
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            onKeyDown={onKeyDown}
                                            value={state.thickness.value}
                                            isValid={state.thickness.isValid}
                                            type="number"
                                            title={<Fragment>Grubość płytki - t<sub>p</sub> [mm]</Fragment>}
                                            placeholder={'Grubość płytki'}
                                            name="thickness"

                                            required
                                        />
                                    </Col>                            
                                </Fragment>
                            }
                        </Row>
                        <Row>
                            <Col xs={12} sm={4} className={styles.columnSpace}>
                                <InputField
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                    value={state.d.value}
                                    isValid={state.d.isValid}
                                    type="number"
                                    title={<Fragment>Średnica otworu - d [mm]</Fragment>}
                                    placeholder={'Średnica otworu'}
                                    name="d"

                                    required
                                />
                            </Col>

                            <Col xs={12} sm={4} className={styles.columnSpace}>
                                <InputField
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                    value={state.dsg.value}
                                    isValid={state.dsg.isValid}
                                    type="number"
                                    title={<Fragment>Średnica nakrętki - d<sub>SG</sub> [mm]</Fragment>}
                                    placeholder={'Średnica nakrętki'}
                                    name="dsg"

                                    required
                                />
                            </Col>

                            <Col xs={12} sm={4} className={styles.columnSpace}>
                                <SelectField
                                    title="Klasa stali płyty"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={state.plateSteelClass.value}
                                    defaultOption="Wybierz klasę stali"
                                    isValid={state.plateSteelClass.isValid}
                                    options={Object.keys(plateSteelGrades)}
                                    name="plateSteelClass"

                                    required
                                />
                            </Col>

                        </Row>

                        {
                            children
                        }
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

export default Calculations;
