import { Fragment, useState } from 'react';
import moment from 'moment';
import {
    Modal,
    Button,
    SectionTitle,
    ConfirmModal,
    Checkbox1,
} from '../../../components';
import styles from './index.module.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
    faTrashAlt,
    faEdit,
    faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

import { genesisType } from '../../../config/ProjectConfig';
import DefaultSoil from '../../VerticalCapacity/model/SoilTable/DefaultSoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverwriteProfile from './OverwriteProfile';
import SoilTableModal from './SoilTableModal';
import { Row, Col } from 'react-bootstrap';

const Profiles = ({
    data = {},
    loading = {},
    defaultSoilLayer = {},
    profiles = [],
    currentProject = {},
    currentProfileName = '',

    isUserRole,
    userId,
    id,
}) => {
    const [open, setOpen] = useState({});
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openLayerModal, setOpenLayerModal] = useState(false);
    const [index, setIndex] = useState(null);
    const [infoObj, setInfoObj] = useState({});
    const [type, setType] = useState('');

    const openDefaultSoilModal = (i) => {
        setIndex(i);
        setOpenLayerModal(true);
    };
    const onOpenInfoModal = (obj) => {
        setInfoObj(obj);
        setOpenInfoModal(true);
    };
    const onHideInfoModal = () => {
        setInfoObj({});
        setOpenInfoModal(false);
    };
    const hideDefaultSoilModal = () => setOpenLayerModal(false);

    const onOpen = (e) =>
        setOpen((state) => ({ ...state, [e.currentTarget.name]: true }));
    const onHide = () => setOpen({});

    const onEditProfile = (id) => {
        data.onLoadProfile(id);
        setOpen((state) => ({ ...state, editProfile: true }));
    };

    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [profileName, setProfileName] = useState('');
    const [profileId, setProfileId] = useState('');

    const onOpenProfileModal = (id, name, type='') => {
        setProfileName(name);
        setProfileId(id);
        setType(type);
        setOpenConfirmModal(true);
    };
    const onDeleteProfile = () => {
        setOpenConfirmModal(false);
        data.onRemoveProfile(profileId);
    };
    const onDeleteCptProfile = () => {
        setOpenConfirmModal(false);
        data.onRemoveCptProfile(profileId);
    };
    const onHideConfirmModal = () => setOpenConfirmModal(false);

    return (
        <Fragment>
            <div className={styles.inlineNavbar}>
                <SectionTitle title="Profile gruntowe" />
                {currentProject.id && (
                    <Button
                        icon={faPlus}
                        css={`
                            ${styles.loadButton} default-button-style
                        `}
                        variant="info"
                        name="createProfile"
                        onClick={onOpen}
                        altTitle="Nowy profil"
                    />
                )}
            </div>
            {profiles.length > 0 ? (
                <div className={`${styles.wrapper} backgroundAround`}>
                    <ul className={styles.ul}>
                        {profiles.map((ele, idx) => {
                            return (
                                <li key={idx}>
                                    <div className={styles.projectWrapper}>
                                        <p
                                            className={`${
                                                currentProfileName === ele.profileName ? styles.currentProfile : ''
                                            } ${styles.p}`}
                                            onClick={() =>
                                                data.onLoadCalculations(ele.id)
                                            }>{`${idx + 1}. ${ele.type === 'cpt' ? `${ele.profileName} (CPT)` : ele.profileName}`}</p>
                                        <div className={styles.profileActions}>
                                            <Checkbox1
                                                value={data.state.calculationsIdArray.includes(
                                                    ele.id,
                                                )}
                                                onChange={() =>
                                                    data.onLoadCalculations(
                                                        ele.id,
                                                    )
                                                }
                                                label={
                                                    'Wybierz profil gruntowy'
                                                }
                                                name={'soilProfile'}
                                            />
                                            <div
                                                className={
                                                    styles.editSoilProfile
                                                }
                                                title="Edytuj profil"
                                                onClick={() =>
                                                    onEditProfile(ele.id)
                                                }>
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                />
                                            </div>
                                            {(isUserRole || id === userId) && (
                                                <div
                                                    className={
                                                        styles.editSoilProfile
                                                    }
                                                    title="Usuń profil"
                                                    onClick={() =>
                                                        onOpenProfileModal(
                                                            ele.id,
                                                            ele.profileName,
                                                            ele.type
                                                        )
                                                    }>
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    styles.editSoilProfile
                                                }
                                                title="Informacje o profilu gruntowym"
                                                onClick={() =>
                                                    onOpenInfoModal({
                                                        user: ele.author,
                                                        projectName:
                                                            ele.projectId.name,
                                                        profileName:
                                                            ele.profileName,
                                                        created: ele.createdAt,
                                                        updated: ele.updatedAt,
                                                    })
                                                }>
                                                <FontAwesomeIcon
                                                    icon={faQuestionCircle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : (
                <Fragment>
                    {!currentProject.id ? (
                        <p className={styles.center}>Wybierz projekt</p>
                    ) : (
                        <p className={styles.center}>
                            Brak profili gruntowych dla wybranego projektu
                        </p>
                    )}
                </Fragment>
            )}
            <Modal
                open={openInfoModal}
                cssDialog={styles.modalDialogInfo}
                onHide={onHideInfoModal}
                hideButton={false}
                title={`Informacje o profilu gruntowym - ${infoObj.profileName}`}>
                <div className={styles.infoWrapper}>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Nazwa profilu gruntowego"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>{infoObj.profileName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Nazwa projektu"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>{infoObj.projectName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Autor profilu gruntowego"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {infoObj.user
                                    ? `${infoObj.user.firstName} ${infoObj.user.lastName}, ${infoObj.user.email}`
                                    : ''}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Data utworzenia profilu gruntowego"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {moment(infoObj.created)
                                    .local('pl')
                                    .format('DD.MM.YYYY, HH:mm')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SectionTitle
                                title="Data ostatniej aktualizacji profilu gruntowego"
                                className={styles.h2Title}
                            />
                        </Col>
                        <Col xs={12}>
                            <p>
                                {moment(infoObj.updated)
                                    .local('pl')
                                    .format('DD.MM.YYYY, HH:mm')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <ConfirmModal
                open={openConfirmModal}
                onHide={onHideConfirmModal}
                onConfirm={type === 'cpt' ? onDeleteCptProfile : onDeleteProfile}
                title="Usuń profil gruntowy">
                <p>
                    Jesteś pewny, że chcesz usunąć profil gruntowy -{' '}
                    <b>{profileName}</b>?
                </p>
            </ConfirmModal>
            <SoilTableModal
                open={open.editProfile}
                title={`Edytuj profil - ${data.state.profileName}`}
                onHide={onHide}
                soilTable={data.soilTable}
                onChangeSoilState={data.onChangeSoilState}
                onBlurSoilState={data.onBlurSoilState}
                onKeyDown={data.onKeyDown}
                removeRow={data.soilTable.removeRow}
                addRow={data.soilTable.addRow}
                openDefaultSoilModa={openDefaultSoilModal}
                saveButtonTitle={'Zapisz zmiany'}
                loading={loading.state.overwriteSoilProfile}
                defaultSoils={data.defaultSoils}
                onSubmit={data.onOverwrite}
                hideProfileName={true}
            />
            <SoilTableModal
                open={open.createProfile}
                title={'Nowy profil'}
                onHide={onHide}
                onChange={data.onChange}
                onBlur={data.onBlur}
                profileName={data.state.newProfileName}
                soilTable={data.newSoilTable}
                onChangeSoilState={data.onChangeNewSoilState}
                onBlurSoilState={data.onBlurNewSoilState}
                onKeyDown={data.onKeyDown}
                removeRow={data.newSoilTable.removeRow}
                addRow={data.newSoilTable.addRow}
                openDefaultSoilModa={openDefaultSoilModal}
                saveButtonTitle={'Zapisz profil'}
                loading={loading.state.creatingProfile}
                defaultSoils={data.defaultSoils}
                onSubmit={data.onSubmit}
            />
            <DefaultSoil
                {...defaultSoilLayer}
                open={openLayerModal}
                onHide={hideDefaultSoilModal}
                genesisType={genesisType}
                rowIndex={index}
            />
            <OverwriteProfile
                onHideExistProfileModal={data.onHideExistsProfileModal}
                open={data.state.profileExists.value}
                overwriteLoading={loading.state.overwriteExistsSoilProfile}
                profileName={data.state.profileName.value}
                onSubmit={data.onOverwriteNewProfile}
            />
        </Fragment>
    );
};

export default Profiles;