import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import {
    ResultsFooter, 
    ResultsHeader,
    ReportFirstPage,
} from '../../../../../components';
import styles from './index.module.css';
import CalculationInput from '../CalculationInput';
import ProjectInfo from '../ProjectInfo';
import ModelFactors from '../ModelFactors';
import SecondOrder from '../SecondOrder';
import SummaryResults from '../SummaryResults';
import InitForces from '../InitForces';
import SummaryShearResults from '../SummaryShearResults';
import ResultsCapacityDetails from '../ResultsCapacityDetails';
import CapcityChart from '../CapcityChart';
import SecondOrderResults from '../SecondOrderResults';
import CrossSection from '../CrossSection';

const PdfReport = React.forwardRef(({ pdfState, setLoading }, ref) => {

    const [footerHeight, setFooterHeight] = useState(0);
    const contentRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState(0);
    const [dataURI, setDataURI] = useState([]);

    useEffect(() =>{
        if(contentRef.current){
            setMaxWidth(contentRef.current.offsetWidth)
        }
    },[]);
    
    useEffect(() => {
        if(pdfState.reportItem.capacityChart){
            setLoading(Object.values(dataURI).length < pdfState.results.calculation.length);
        } else {
            setLoading(false);
        }
        
    },[Object.values(dataURI).length, pdfState.reportItem.capacityChart]);

    const onDataURI = (idx) => {

        return (data) => {
            setDataURI(state => {
                return {
                    ...state,
                    [idx]: data
                }
            })
        }
    }

    return (
        <Fragment>
            <div className={styles.wrapper} ref={ref}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td>
                                <div className={styles.headerSpace}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <main className={styles.content} ref={contentRef}>
                                    <ol>
                                        <div> 
                                            {
                                                pdfState.reportItem.firstPage &&
                                                <Fragment>
                                                    <ReportFirstPage
                                                        title="Zbrojenie przekroju zgodnie z Eurokodem 2"
                                                        subtitle={
                                                            pdfState.projectTitle
                                                                .projectTitle
                                                        }
                                                        author={pdfState.report.author}
                                                        contact={pdfState.report.contact}
                                                        logo={pdfState.report.logo}
                                                    />                                          
                                                </Fragment>
                                            }
                                            {(pdfState.reportItem.calculationInput || pdfState.reportItem.projectInfo || pdfState.reportItem.modelFactors || pdfState.reportItem.secondOrderModel) && (
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Dane wejściowe
                                                    </li> 
                                                    {
                                                        pdfState.reportItem.calculationInput &&
                                                        <CalculationInput
                                                            memberType={pdfState.results.initModelValues.memberType}
                                                            parameters={{
                                                                circular: pdfState.results.initModelValues.circular,
                                                                ...pdfState.calculationInput,
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        pdfState.reportItem.projectInfo &&
                                                        <ProjectInfo
                                                            memberType={pdfState.results.initModelValues.memberType}
                                                            parameters={{
                                                                ...pdfState.calculationInput,
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        pdfState.reportItem.modelFactors &&
                                                        <ModelFactors
                                                            parameters={{
                                                                ...pdfState.calculationInput,
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        pdfState.reportItem.secondOrderModel && pdfState.results.initModelValues.circular &&
                                                        <SecondOrder
                                                            parameters={{
                                                                ...pdfState.calculationInput
                                                            }}
                                                        />
                                                    }
                                                </Fragment>
                                            )}
                                            {( 
                                                pdfState.results.initModelValues.memberType !== 'Płyta'
                                                ?
                                                pdfState.reportItem.summary ||
                                                pdfState.reportItem.shearSummary ||
                                                pdfState.reportItem.secondOrder ||
                                                pdfState.reportItem.capacityChart ||
                                                pdfState.reportItem.capacityDetails 
                                                :
                                                pdfState.reportItem.summary ||
                                                pdfState.reportItem.capacityChart ||
                                                pdfState.reportItem.capacityDetails 
                                            )
                                            &&
                                            <Fragment>
                                                <div className={styles.break}></div>
                                                <li className={styles.h1}>
                                                    Wyniki obliczeń
                                                </li> 
                                                {
                                                    pdfState.results.calculation.map((ele,idx) => {
                                                        return ( 
                                                            <Fragment key={idx}>
                                                                {
                                                                    (   pdfState.results.initModelValues.memberType !== 'Płyta'
                                                                        ?
                                                                        pdfState.reportItem.summary ||
                                                                        pdfState.reportItem.shearSummary ||
                                                                        pdfState.reportItem.secondOrder ||
                                                                        pdfState.reportItem.capacityDetails
                                                                        :
                                                                        pdfState.reportItem.summary ||
                                                                        pdfState.reportItem.capacityDetails
                                                                    ) &&
                                                                        <InitForces
                                                                            memberType={pdfState.results.initModelValues.memberType}
                                                                            initMed={ele.defaultResults.initMed}
                                                                            med={ele.defaultResults.med}
                                                                            ned={ele.defaultResults.ned}
                                                                            medp={ele.defaultResults.medp}
                                                                            shearForce={ele.defaultResults.shearForce}
                                                                            secondOreder={ele.defaultResults.secondOrder}
                                                                            ea={ele.defaultResults.ea}
                                                                        />  
                                                                }

                                                                {
                                                                    pdfState.reportItem.summary &&
                                                                    <Fragment>
                                                                        <SummaryResults
                                                                            circular={pdfState.results.initModelValues.circular}
                                                                            memberType={pdfState.results.initModelValues.memberType}
                                                                            b={pdfState.results.initModelValues.b}
                                                                            d={pdfState.results.initModelValues.d}
                                                                            diameter={pdfState.results.initModelValues.h}
                                                                            results={ele}
                                                                            checkCapacity={pdfState.checkCapacity.find(e => e.index === ele.defaultResults.index)}
                                                                        />
                                                                    </Fragment>
                                                                }
                                                                {
                                                                    pdfState.reportItem.shearSummary && pdfState.results.initModelValues.memberType !== 'Płyta' &&
                                                                    <Fragment>
                                                                        <SummaryShearResults
                                                                            result={ele} 
                                                                            circular={pdfState.results.initModelValues.circular}
                                                                            stirrupType={pdfState.results.initModelValues.stirrupType}
                                                                            spiral={pdfState.results.initModelValues.spiral}
                                                                            nCut={pdfState.results.initModelValues.nCut}
                                                                        />
                                                                    </Fragment>
                                                                }
                                                            {
                                                                    pdfState.reportItem.secondOrder &&
                                                                    <Fragment>
                                                                        <SecondOrderResults
                                                                            result={ele} 
                                                                            circular={pdfState.results.initModelValues.circular}
                                                                        />
                                                                    </Fragment>
                                                                }
                                                                {
                                                                    pdfState.reportItem.capacityChart &&
                                                                    <div className={styles.avoidBreakInside}>
                                                                        <h2>Nośność przekroju - wykres interakcji</h2>
                                                                        <div className={styles.imgBorder} style={{ width: `${maxWidth}px` }}>
                                                                            <img alt='' src={dataURI[ele.defaultResults.index]} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    pdfState.reportItem.capacityDetails &&
                                                                    <ResultsCapacityDetails
                                                                        result={ele}
                                                                    />
                                                                }

                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                            }
                                            {
                                                pdfState.reportItem.sectionChart &&
                                                <Fragment>
                                                    <div className={styles.break}></div>
                                                    <li className={styles.h1}>
                                                        Załączniki
                                                    </li> 
                                                    {
                                                        pdfState.results.calculation.map((ele,idx) => {
                                                            return (
                                                                <Fragment key={idx}>
                                                                    <CrossSection
                                                                        dimension={pdfState.results.initModelValues.h}
                                                                        width={pdfState.results.initModelValues.b}
                                                                        coverUp={pdfState.results.initModelValues.coverUp}
                                                                        coverBottom={pdfState.results.initModelValues.coverBottom}
                                                                        circular={pdfState.results.initModelValues.circular}
                                                                        memberType={pdfState.results.initModelValues.memberType}
                                                                        result={ele}
                                                                        capacity={pdfState.checkCapacity.find(e => e.index === ele.defaultResults.index)}
                                                                    />
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            }
                                        </div>
                                    </ol>
                                </main>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div
                                    style={{ height: footerHeight }}
                                    className={styles.footerSpace}></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {
                    pdfState.reportItem.capacityChart &&

                    <Fragment>
                        {
                            pdfState.results.calculation.map((ele,idx) => {
                                return (
                                    <div key={idx} className={styles.chartsWrapper}>
                                        <CapcityChart
                                            maxWidth={maxWidth}
                                            chartTitle="Nośność przekroju - wykres interakcji"
                                            yLabel="N,rd [kN]"
                                            xLabel="M,rd [kNm]"
                                            idx={idx}
                    
                                            circular={pdfState.results.initModelValues.circular}
                                            calcResult={ele}
                                            results={pdfState.sectionCapacity.find(e => e.index === ele.defaultResults.index)}

                                            setDataURI={onDataURI(ele.defaultResults.index)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </Fragment>
                }
                <ResultsHeader
                    author={pdfState.report.author}
                    contact={pdfState.report.contact}
                />
                <ResultsFooter
                    setFooterHeight={useCallback((val) => setFooterHeight(val),[])}
                    pdfState={pdfState.reportItem}
                    globalTitle="Zbrojenie przekroju zgodnie z Eurokodem 2"
                    title={pdfState.projectTitle.projectTitle}
                />
            </div>
        </Fragment>
    );
});

export default PdfReport;