import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    micropileSoils,
    cohesiveSoilType,
    nonCohesiveSoilType,
    isPlasticityCorrect,
    isDensityCorrect,
    isObjectId,
} from '../../../config/ProjectConfig';
import { AuthFetch } from '../../../routes';
import { isNumber, makeNumberPositive, stringToNumber } from '../../../utils';
import { useIsMountedState } from '../../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultRow = {
    name: {
        value: '',
        isValid: null,
    },
    height: {
        value: '',
        isValid: null,
    },
    absHeight: {
        value: '',
    },
    weight: {
        value: '',
        isValid: null,
    },
    underWaterWeight: {
        value: '',
        isValid: null,
    },
    cohesion: {
        value: '',
        isValid: null,
    },
    friction: {
        value: '',
        isValid: null,
    },
    edometricModule: {
        value: '',
        isValid: null,
    },
    qccpt: {
        value: '',
        isValid: null,
    },
    cucpt: {
        value: '',
        isValid: null,
    },
    density: {
        value: '',
        isValid: null,
    },
    plasticity: {
        value: '',
        isValid: null,
    },
    soilName: {
        value: '',
        isValid: null,
    },
    genesis: {
        value: '',
        isValid: null,
    },
    soilType: {
        value: '',
        isValid: null,
    },
};
const flattenArray = (arr) => {
    if (arr) {
        return [].concat.apply(
            [],
            Object.values(arr).filter((ele) => Array.isArray(ele)),
        );
    } else {
        return [];
    }
};

const onChangeCurrentSoilState = ({ state, input }) => {
    const rowNumber = stringToNumber(input.getAttribute('data-row'));
    const { name, value, type } = input;

    return state.map((element, index) => {
        const soil = micropileSoils.find(
            (ele) => ele.name === element.soilName.value,
        );
        const soilState = soil ? soil.state : '';

        const currentSoil = micropileSoils.find((ele) => ele.name === value);
        const currentSoilState = currentSoil ? currentSoil.state : '';

        const nonCohesiveCollection = Object.values(nonCohesiveSoilType).map(
            (ele) => ele.pl,
        );
        const cohesiveCollection = Object.values(cohesiveSoilType).map(
            (ele) => ele.pl,
        );

        const isNonCohesiveSoilType = nonCohesiveCollection.includes(
            element.soilType.value,
        );
        const isCohesiveSoilType = cohesiveCollection.includes(
            element.soilType.value,
        );

        const isCurrentNonCohesiveSoilType =
            nonCohesiveCollection.includes(value);
        const isCurrentCohesiveSoilType = cohesiveCollection.includes(value);

        if (rowNumber !== index) {
            return element;
        } else {
            return {
                ...element,
                ...(name === 'density' && {
                    plasticity: {
                        value: '',
                        isValid: null,
                    },
                    genesis: {
                        value: '',
                        isValid: null,
                    },
                    cohesion: {
                        value: '',
                        isValid: null,
                    },
                    soilType: {
                        value: isDensityCorrect(stringToNumber(value))
                            ? isCohesiveSoilType
                                ? ''
                                : element.soilType.value
                            : element.soilType.value,
                        isValid: isDensityCorrect(stringToNumber(value))
                            ? isCohesiveSoilType
                                ? null
                                : element.soilType.isValid
                            : element.soilType.isValid,
                    },
                    soilName: {
                        value: isDensityCorrect(stringToNumber(value))
                            ? ['cohesive', 'organic', 'rock'].includes(
                                  soilState,
                              )
                                ? ''
                                : element.soilName.value
                            : element.soilName.value,
                        isValid: isDensityCorrect(stringToNumber(value))
                            ? ['cohesive', 'organic', 'rock'].includes(
                                  soilState,
                              )
                                ? null
                                : element.soilName.isValid
                            : element.soilName.isValid,
                    },
                }),
                ...(name === 'plasticity' && {
                    density: {
                        value: '',
                        isValid: null,
                    },
                    soilType: {
                        value: isPlasticityCorrect(stringToNumber(value))
                            ? isNonCohesiveSoilType
                                ? ''
                                : element.soilType.value
                            : element.soilType.value,
                        isValid: isPlasticityCorrect(stringToNumber(value))
                            ? isNonCohesiveSoilType
                                ? null
                                : element.soilType.isValid
                            : element.soilType.isValid,
                    },
                    soilName: {
                        value: isPlasticityCorrect(stringToNumber(value))
                            ? soilState === 'noncohesive' ||
                              soilState === 'rock'
                                ? ''
                                : element.soilName.value
                            : element.soilName.value,
                        isValid: isPlasticityCorrect(stringToNumber(value))
                            ? soilState === 'noncohesive' ||
                              soilState === 'rock'
                                ? null
                                : element.soilName.isValid
                            : element.soilName.isValid,
                    },
                }),
                ...(name === 'density' &&
                    (soilState === 'cohesive' || soilState === 'organic') && {
                        soilName: {
                            value: '',
                            isValid: null,
                        },
                    }),
                ...(name === 'plasticity' &&
                    soilState === 'noncohesive' && {
                        soilName: {
                            value: '',
                            isValid: null,
                        },
                    }),
                ...(name === 'soilName' &&
                    currentSoilState === 'noncohesive' && {
                        plasticity: {
                            value: '',
                            isValid: null,
                        },
                        genesis: {
                            value: '',
                            isValid: null,
                        },
                        density: {
                            value: isNumber(element.plasticity.value)
                                ? element.plasticity.value
                                : element.density.value,
                            isValid: isNumber(element.plasticity.value)
                                ? isDensityCorrect(element.plasticity.value)
                                : element.density.isValid,
                        },
                        soilType: {
                            value: isCohesiveSoilType
                                ? ''
                                : element.soilType.value,
                            isValid: isCohesiveSoilType
                                ? null
                                : element.soilType.isValid,
                        },
                    }),
                ...(name === 'soilName' &&
                    (currentSoilState === 'cohesive' ||
                        currentSoilState === 'organic') && {
                        density: {
                            value: '',
                            isValid: null,
                        },
                        plasticity: {
                            value: isNumber(element.density.value)
                                ? element.density.value
                                : element.plasticity.value,
                            isValid: isNumber(element.density.value)
                                ? isPlasticityCorrect(element.density.value)
                                : element.plasticity.isValid,
                        },
                        soilType: {
                            value: isNonCohesiveSoilType
                                ? ''
                                : element.soilType.value,
                            isValid: isNonCohesiveSoilType
                                ? null
                                : element.soilType.isValid,
                        },
                    }),
                ...(name === 'soilName' &&
                    soilState === 'rock' && {
                        density: {
                            value: '',
                            isValid: null,
                        },
                        plasticity: {
                            value: '',
                            isValid: null,
                        },
                    }),
                ...(name === 'genesis' && {
                    density: {
                        value: '',
                        isValid: null,
                    },
                    plasticity: {
                        value: isNumber(element.density.value)
                            ? element.density.value
                            : element.plasticity.value,
                        isValid: isNumber(element.density.value)
                            ? isPlasticityCorrect(element.density.value)
                            : element.plasticity.isValid,
                    },
                    soilType: {
                        value: isPlasticityCorrect(
                            stringToNumber(element.plasticity.value),
                        )
                            ? isNonCohesiveSoilType
                                ? ''
                                : element.soilType.value
                            : '',
                        isValid: isPlasticityCorrect(
                            stringToNumber(element.plasticity.value),
                        )
                            ? isNonCohesiveSoilType
                                ? null
                                : element.soilType.isValid
                            : null,
                    },
                    soilName: {
                        value:
                            soilState === 'noncohesive'
                                ? ''
                                : element.soilName.value,
                        isValid:
                            soilState === 'noncohesive'
                                ? null
                                : element.soilName.isValid,
                    },
                }),
                ...(name === 'cohesion' && {
                    density: {
                        value: '',
                        isValid: null,
                    },
                    soilType: {
                        value: isPlasticityCorrect(
                            stringToNumber(element.plasticity.value),
                        )
                            ? isNonCohesiveSoilType
                                ? ''
                                : element.soilType.value
                            : '',
                        isValid: isPlasticityCorrect(
                            stringToNumber(element.plasticity.value),
                        )
                            ? isNonCohesiveSoilType
                                ? null
                                : element.soilType.isValid
                            : null,
                    },
                    soilName: {
                        value:
                            soilState === 'noncohesive'
                                ? ''
                                : element.soilName.value,
                        isValid:
                            soilState === 'noncohesive'
                                ? null
                                : element.soilName.isValid,
                    },
                }),
                ...(name === 'soilType' &&
                    isCurrentCohesiveSoilType && {
                        density: {
                            value: '',
                            isValid: null,
                        },
                        plasticity: {
                            value: isNumber(element.density.value)
                                ? element.density.value
                                : element.plasticity.value,
                            isValid: isNumber(element.density.value)
                                ? isPlasticityCorrect(element.density.value)
                                : element.plasticity.isValid,
                        },
                        soilName: {
                            value:
                                soilState === 'noncohesive'
                                    ? ''
                                    : element.soilName.value,
                            isValid:
                                soilState === 'noncohesive'
                                    ? null
                                    : element.soilName.isValid,
                        },
                    }),
                ...(name === 'soilType' &&
                    isCurrentNonCohesiveSoilType && {
                        genesis: {
                            value: '',
                            isValid: null,
                        },
                        plasticity: {
                            value: '',
                            isValid: null,
                        },
                        cohesion: {
                            value: '',
                            isValid: null,
                        },
                        density: {
                            value: isNumber(element.plasticity.value)
                                ? element.plasticity.value
                                : element.density.value,
                            isValid: isNumber(element.plasticity.value)
                                ? isDensityCorrect(element.plasticity.value)
                                : element.density.isValid,
                        },
                        soilName: {
                            value: ['cohesive', 'organic'].includes(soilState)
                                ? ''
                                : element.soilName.value,
                            isValid: ['cohesive', 'organic'].includes(soilState)
                                ? null
                                : element.soilName.isValid,
                        },
                    }),

                [name]: {
                    value:
                        type === 'number' && value.length > 0
                            ? name === 'plasticity'
                                ? Number(value)
                                : makeNumberPositive(Number(value))
                            : value,
                    isValid: true,
                },
            };
        }
    });
};

const onBlurCurrentSoilState = ({ e, stateFunction }) => {
    const input = e.target.closest('input') || e.target.closest('select');

    if (input) {
        const { name } = input;
        const rowNumber = Number(input.getAttribute('data-row'));
        const valid = input.checkValidity();

        if (name === 'height') {
            stateFunction((state) =>
                state.map((element, index, arr) => {
                    return {
                        ...element,
                        height: {
                            ...element['height'],
                            isValid:
                                valid &&
                                arr
                                    .slice(0, index)
                                    .every(
                                        (ele) =>
                                            ele.height.value <
                                            element.height.value,
                                    ),
                        },
                    };
                }),
            );
        } else {
            stateFunction((state) =>
                state.map((element, index, arr) => {
                    if (rowNumber !== index) {
                        return element;
                    } else {
                        return {
                            ...element,
                            [name]: {
                                ...element[name],
                                isValid: valid,
                            },
                        };
                    }
                }),
            );
        }
    }
};

const onUpdateCurrentSoilState = (state) => {
    return state.map((element, index, arr) => {
        if (index === 0) {
            return {
                ...element,
                absHeight: {
                    value: isNumber(element.height.value)
                        ? Number(element.height.value)
                        : '',
                },
            };
        } else {
            return {
                ...element,
                absHeight: {
                    value:
                        isNumber(element.height.value) &&
                        isNumber(arr[index - 1].height.value)
                            ? element.height.value -
                                  arr[index - 1].height.value >
                              0
                                ? Number(
                                      (
                                          element.height.value -
                                          arr[index - 1].height.value
                                      ).toFixed(2),
                                  )
                                : ''
                            : '',
                },
            };
        }
    });
};
const ProfilesListHelpers = ({
    setLoading,
    setAlert,
    fetch,
    currentProject,
    updateCurrentProject,
}) => {
    const [profileName, setProfileName] = useState('');
    const [newProfileName, setNewProfileName] = useState({
        value: '',
        isValid: null,
    });
    const [profileExists, setProfileExists] = useState({
        value: false,
    });

    const [soilTableState, setSoilTableState] = useState([{ ...defaultRow }]);
    const [soilNewTableState, setSoilNewTableState] = useState([
        { ...defaultRow },
    ]);

    const [currentProfileId, setCurrentProfileId] = useState('');
    const [currentProfileName, setCurrentProfileName] = useState('');
    const [calculationsInProfile, setCalculationsInProfile] = useState([]);
    const [calculationsIdArray, setCalculationsIdArray] = useState([]);
    const isMountedState = useIsMountedState();


    const soilTableHeight = soilTableState.map(({ height }) => ({ height }));
    const soilNewTableHeight = soilNewTableState.map(({ height }) => ({ height }));

    useDeepCompareEffect(() => {
        setSoilTableState((state) => onUpdateCurrentSoilState(state));
    }, [soilTableHeight]);

    useDeepCompareEffect(() => {
        setSoilNewTableState((state) => onUpdateCurrentSoilState(state));
    }, [soilNewTableHeight]);

    useEffect(() => {
        setCalculationsIdArray([]);
        setCalculationsInProfile([]);
        setCurrentProfileName('');
    }, [currentProject.id]);

    useDeepCompareEffect(() => {
        if (isObjectId(currentProject.id)) {
            const profilesID = calculationsIdArray.length > 0 ? calculationsIdArray.join(';') : null;
            AuthFetch({
                url: `${fetch.calculations.url}/${currentProject.id}/${profilesID}`,
                method: fetch.calculations.method,
            }).then((response) => {
                if(isMountedState.current){
                    if (response.success) {
                        setCalculationsInProfile(
                            response.responseData.map((ele) => {
                                const { id, profileName, ...rest } = ele;

                                return {
                                    profileId: id,
                                    profileName: profileName,
                                    calculations: flattenArray(rest),
                                };
                            }),
                        );
                    } else {
                        setAlert({
                            error: true,
                            message: 'Błąd podczas wczytywania obliczeń',
                        });
                    }                    
                }
            })
            .catch(e => {
                if(isMountedState.current){
                    setAlert({
                        error: true,
                        message: 'Błąd podczas wczytywania obliczeń',
                    });
                }
            })
        }
    }, [
        currentProject.id,
        fetch.calculations.url,
        fetch.calculations.method,
        calculationsIdArray,
        setAlert
    ]);

    const onChange = useCallback((e) => {
        const target = e.currentTarget;
        if (target) {
            setNewProfileName((state) => ({
                ...state,
                value: target.value,
            }));
        }
    }, []);

    const onBlur = useCallback((e) => {
        const target = e.currentTarget;

        setNewProfileName((state) => ({
            ...state,
            isValid: target ? target.checkValidity() : false,
        }));
    }, []);

    const onSubmit = () => {
        if (newProfileName.isValid) {
            setLoading({ name: 'creatingProfile', loading: true });
            AuthFetch({
                url: fetch.create.url,
                method: fetch.create.method,
                body: {
                    profileName: newProfileName.value,
                    projectId: currentProject.id,
                    soilsCollection: soilNewTableState,
                },
            }).then((response) => {
                setLoading({ name: 'creatingProfile', loading: false });
                if (response.success) {
                    if (response.responseData.exists) {
                        setProfileExists((state) => ({
                            ...state,
                            value: true,
                        }));
                    } else {
                        updateCurrentProject((state) => {
                            return {
                                ...state,
                                profiles: [
                                    ...state.profiles,
                                    {
                                        id: response.responseData.id,
                                        profileName:
                                            response.responseData.profileName,
                                    },
                                ],
                            };
                        });
                        setAlert({
                            error: false,
                            message: 'Profil geologiczny został zapisany',
                            hideTime: 3000,
                        });
                        setTimeout(() => {
                            setNewProfileName({
                                value: '',
                                isValid: null,
                            });
                            setSoilNewTableState([{ ...defaultRow }]);
                        }, 3000);
                    }
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisu profilu geologicznego',
                    });
                }
            });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa profilu geologicznego jest wymagana',
            });
            setNewProfileName((state) => ({
                ...state,
                isValid: !!state.isValid,
            }));
        }
    };

    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const onChangeSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            setSoilTableState((state) =>
                onChangeCurrentSoilState({ state, input }),
            );
        }
    }, []);

    const onBlurSoilState = (e) => onBlurCurrentSoilState({ e: e, stateFunction: setSoilTableState })

    const onChangeNewSoilState = useCallback((e) => {
        const input = e.target.closest('input') || e.target.closest('select');

        if (input) {
            setSoilNewTableState((state) =>
                onChangeCurrentSoilState({ state, input }),
            );
        }
    }, []);
    const onBlurNewSoilState = (e) => onBlurCurrentSoilState({ e: e, stateFunction: setSoilNewTableState });

    const addRowToSoilTable = () =>
        setSoilTableState((state) => [...state, defaultRow]);
    const removeRowFromSoilTable = (i) =>
        setSoilTableState((state) => [
            ...state.filter((_, index) => index !== i),
        ]);

    const addRowToNewSoilTable = () =>
        setSoilNewTableState((state) => [...state, defaultRow]);
    const removeRowFromNewSoilTable = (i) =>
        setSoilNewTableState((state) => [
            ...state.filter((_, index) => index !== i),
        ]);

    const onLoadProfile = (id) => {
        setLoading({ name: 'fetchProfile', loading: true });
        AuthFetch({
            url: '/api/soils/parameters/edit/' + id,
            method: 'GET',
        }).then((response) => {
            setLoading({ name: 'fetchProfile', loading: false });
            if (response.success) {
                setProfileName(response.responseData.profileName);
                setCurrentProfileId(response.responseData.id);
                setSoilTableState(response.responseData.soilsCollection);
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas wczytywania profilu geologicznego',
                });
            }
        });
    };

    const onLoadCalculations = (id) => {
        setCalculationsIdArray((state) => {
            const idExists = state.includes(id);
            if (idExists) {
                return state.filter((stateId) => stateId !== id);
            } else {
                return [...state, id];
            }
        });
    };

    const onOverwriteNewProfile = () => {
        if (newProfileName.isValid && isObjectId(currentProject.id)) {
            setLoading({ name: 'overwriteExistsSoilProfile', loading: true });
            AuthFetch({
                url: '/api/soils/parameters/edit',
                method: 'PUT',
                body: {
                    profileName: newProfileName.value,
                    soilsCollection: soilNewTableState,
                    projectId: currentProject.id,
                },
            }).then((response) => {
                setLoading({
                    name: 'overwriteExistsSoilProfile',
                    loading: false,
                });
                if (response.success) {
                    setTimeout(() => {
                        setProfileExists((state) => ({
                            ...state,
                            value: false,
                        }));
                        setNewProfileName({
                            value: '',
                            isValid: null,
                        });
                        setSoilNewTableState([{ ...defaultRow }]);
                    }, 3000);

                    setAlert({
                        error: false,
                        message: 'Profil geologiczny został zapisany',
                        hideTime: 2000,
                    });
                } else {
                    setAlert({
                        error: true,
                        message: 'Błąd podczas zapisu profilu geologicznego',
                    });
                }
            });
        } else {
            setAlert({
                error: true,
                message: 'Nazwa profilu geologicznego jest wymagana',
            });
        }
    };

    const onOverwriteProfile = () => {
        setLoading({ name: 'overwriteSoilProfile', loading: true });
        AuthFetch({
            url: '/api/soils/parameters/edit/' + currentProfileId,
            method: 'PUT',
            body: {
                profileName: profileName,
                soilsCollection: soilTableState,
                projectId: currentProject.id,
            },
        }).then((response) => {
            setLoading({ name: 'overwriteSoilProfile', loading: false });
            if (response.success) {
                setTimeout(() => {
                    setProfileExists((state) => ({ ...state, value: false }));
                }, 2000);

                setAlert({
                    error: false,
                    message: 'Profil geologiczny został zapisany',
                    hideTime: 2000,
                });
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas zapisu profilu geologicznego',
                });
            }
        });
    };

    const onRemoveProfile = (id) => {
        AuthFetch({
            url: `${fetch.delete.url}/${id}`,
            method: fetch.delete.method,
        }).then((response) => {
            if (response.success) {
                setAlert({
                    error: false,
                    message: 'Profil geologiczny został usunięty',
                    hideTime: 2000,
                });

                updateCurrentProject((state) => {
                    return {
                        ...state,
                        profiles: [
                            ...state.profiles.filter(
                                (ele) => ele.id !== response.responseData.id,
                            ),
                        ],
                    };
                });
                setCurrentProfileName('');
                setCalculationsInProfile((state) => [
                    ...state.filter(
                        (ele) => ele.profileId !== response.responseData.id,
                    ),
                ]);
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas usuwania profilu geologicznego',
                });
            }
        });
    };
    const onRemoveCptProfile = (id) => {
        AuthFetch({
            url: `${fetch.deleteCpt.url}/${id}`,
            method: fetch.deleteCpt.method,
        }).then((response) => {
            if (response.success) {
                setAlert({
                    error: false,
                    message: 'Profil geologiczny został usunięty',
                    hideTime: 2000,
                });

                updateCurrentProject((state) => {
                    return {
                        ...state,
                        profiles: [
                            ...state.profiles.filter(
                                (ele) => ele.id !== response.responseData.id,
                            ),
                        ],
                    };
                });
                setCurrentProfileName('');
                setCalculationsInProfile((state) => [
                    ...state.filter(
                        (ele) => ele.profileId !== response.responseData.id,
                    ),
                ]);
            } else {
                setAlert({
                    error: true,
                    message: 'Błąd podczas usuwania profilu geologicznego',
                });
            }
        });
    };
    const onHideExistsProfileModal = () =>
        setProfileExists((state) => ({ ...state, value: false }));

    const createProfile = {
        state: {
            profileExists,
            newProfileName,
            profileName,
            currentProfileName,
            calculationsInProfile,
            calculationsIdArray,
        },
        soilTable: {
            state: soilTableState,
            addRow: addRowToSoilTable,
            removeRow: removeRowFromSoilTable,
        },
        newSoilTable: {
            state: soilNewTableState,
            addRow: addRowToNewSoilTable,
            removeRow: removeRowFromNewSoilTable,
        },
        defaultSoils: useMemo(
            () => [...micropileSoils.map((ele) => ele.name)],
            [],
        ),
        onChange: onChange,
        onKeyDown: onKeyDown,
        onChangeSoilState: onChangeSoilState,
        updateSoilState: setSoilTableState,
        onBlur: onBlur,
        onBlurSoilState: onBlurSoilState,
        onSubmit: onSubmit,
        onOverwrite: onOverwriteProfile,
        onHideExistsProfileModal: onHideExistsProfileModal,
        onLoadProfile: onLoadProfile,
        onLoadCalculations: onLoadCalculations,
        onRemoveProfile: onRemoveProfile,
        onRemoveCptProfile: onRemoveCptProfile,
        onOverwriteNewProfile: onOverwriteNewProfile,
        updateCalculationsInProfile: setCalculationsInProfile,
        onChangeNewSoilState: onChangeNewSoilState,
        onBlurNewSoilState: onBlurNewSoilState,
    };

    return {
        createProfile,
    };
};

export default ProfilesListHelpers;