import { Fragment } from "react"
import { InputField } from "..";
import styles from './index.module.css'; 
import { passwordLength } from "../../config/ProjectConfig/projectPatterns";

const Password = ({ onChange, value, isValid, name='password', title='Hasło', ...rest }) => {

    return (
        <Fragment>
            <InputField
                title={title}
                isValid={isValid}
                type="password"
                name={name}
                value={value} 
                placeholder={title}
                onChange={(e) => onChange(e, e.currentTarget.closest('input').value.length >= passwordLength)}

                {...rest}
            />
            {isValid === false && <div>
                <ul className={styles.ul}>
                    <li>Hasło musi zawierać co najmniej 8 znaków</li>
                </ul>                            
            </div>}
        </Fragment>
    )
}

export default Password 