import React, { useState } from 'react';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ResetPasswordForm from './ResetPasswordForm';
import { useParams } from 'react-router-dom';
import { useIsMountedState } from '../../hooks';
import { AuthFetch } from '../../routes';

const Container = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    & form {
        & .form-group {
            margin: 10px 0;
        }
        & button {
            margin: 15px 0
        }
    }

`;

const ResetPassword = () => {
    const [messageState, setMessageState] = useState({
        message: '',
        open: false,
        success: true,
    });
    const [responseData, setResponseData] = useState({})
    const { id } = useParams();
    const [loadingState, setLoadingState] = useState({
        isLoaded: false,
        name: '',
    });
    const isMountedState = useIsMountedState();

    useDeepCompareEffect(() => {
        AuthFetch({
            method: 'GET',
            url: `/api/auth/reset-password/${id}`,
        })
            .then((response) => {
                if (isMountedState.current) {
                    setLoadingState((state) => ({
                        ...state,
                        isLoaded: true,
                    }));

                    if (response.status === 200) {
                        setResponseData(
                            response.responseData
                        )
                    } else {
                        setMessageState({
                            message: 'Wystąpił błąd podczas resetowania hasła',
                            success: response.success,
                            open: true,
                        });
                    }
                }
            })
            .catch((e) => {
                if (isMountedState.current) {
                    setLoadingState((state) => ({
                        ...state,
                        isLoaded: true,
                    }));
                    setMessageState({
                        message: 'Wystąpił błąd podczas resetowania hasła',
                        success: false,
                        open: true,
                    });
                }
            });
    }, [isMountedState ]);

    return (
        <Container>
            <ResetPasswordForm
                responseData={responseData}
                logo={true}
                fallbackRoute="/auth/zaloguj"
                match={{
                    params: {
                        id
                    }
                }}
                {...messageState}
                { ...loadingState }
                renderErrorMessage
            />
        </Container>
    );
}; 

export default ResetPassword;
