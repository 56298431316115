import { isNumber, roundNumber, stringToNumber } from "../../../utils";
import { AuthFetch } from "../../../routes";
import { cptPileTypes } from "../../../config/ProjectConfig";

const SubmitCalculationHelpers = ({ 
    soilTable, 
    calculations,
    saftyFactors, 
    results, 
    setAlert,  
    setLoading 
}) => {

    const onSubmitCalculation = () => { 

        const calculationToSend = Object.fromEntries(Object.entries(calculations.state)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'newEmbankment')[1].value ? !['from', 'to'].includes(ele[0]) : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'negativeFriction')[1].value ? ele[0] !== 'layersName' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'isBaseWidth')[1].value ? ele[0] !== 'baseWidth' : ele)
            .filter((ele,_,arr) => !arr.find(ele => ele[0] === 'cptLevel')[1].value ? ele[0] !== 'cptLevel' : ele)
            .filter((ele,_,arr) => {
                const pileType = arr.find(ele => ele[0] === 'pileType');
                if(cptPileTypes[3] === pileType[1].value){
                    return ele
                } else {
                    return ele[0] !== 'thickness'
                }
            })
            .filter((ele,_,arr) => {
                const pileType = arr.find(ele => ele[0] === 'pileType');
                if((cptPileTypes[6] === pileType[1].value) || (cptPileTypes[7] === pileType[1].value)){
                    return ele[0] !== 'diameterPile'
                } else {
                    return ele[0] !== 'hType'
                }
            })
        );
        const soilTableToSend = soilTable.state.map(element => {
            return Object.fromEntries(Object.entries(element).filter(ele => !['sigm','sigmSum','nk','cohesiveType', 'zSoil', 'cd'].includes(ele[0])));
        })
        const isValidCalculations = Object.entries(calculationToSend).every(([key, value]) => {
            if(key === 'layersName'){
                return true
            } else {
                return value.isValid
            }
        });
        const isValidSoilTableState = soilTableToSend.every(ele => Object.entries(ele).every(([key,value]) => {
            if(key === 'layerNo'){
                return isNumber(stringToNumber(value.value))
            } else if(key === 'layerIndex'){
                return isNumber(stringToNumber(value.value))
            } else {
                return value.isValid
            }
        }));
        const isValidSaftyFactorsState = Object.values(saftyFactors.state).every(ele => ele.isValid);
        if(isValidSoilTableState && isValidCalculations && isValidSaftyFactorsState){
            setLoading({ name: 'calculations', loading: true });
            AuthFetch({
                url: '/api/pile/cpt/calculations/submit', 
                method: 'POST',
                body: {
                    soilTable: soilTableToSend.map(ele => Object.entries(ele).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{})),
                    calculations: Object.entries(calculationToSend).reduce((acc, [key, value]) => {
                        if(key === 'layersName'){
                            return {
                                ...acc,
                                layersName: value
                            }
                        } else {
                            return {
                                ...acc, 
                                [key]: value.value
                            }
                        }
                    },{}),
                    saftyFactors: Object.entries(saftyFactors.state).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }),{}),
                }
            }).then(response => {
                setLoading({ name: 'calculations', loading: false });
                if(response.success){
                    const settlements = results.onCalculateSettlement({
                        rcd: roundNumber(response.responseData.currentResult.Rcd_current/response.responseData.ygq,2),
                        settlements: response.responseData.currentResult,
                        type: response.responseData.pileTypeNumber
                    });

                    results.updateCurrentPileLength(response.responseData.pileLength);
                    results.updateCurrentCapacity({
                        ...response.responseData.currentResult,
                        s: settlements.s,
                        f: settlements.f
                    }); 
                    results.updateState({
                        isResultsActual: true,
                        ...response.responseData
                    });
                } else {
                    setAlert({ error: true, message: 'Błąd podczas wykonywania obliczeń' });
                }
            })
        } else { 
            const invalidSoilState = soilTable.state.map(ele => Object.entries(ele).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }),{}));

            !isValidSoilTableState && setAlert({ error: true, message: 'Nieprawidłowe parametry podłoża gruntowego' });
            !isValidCalculations && setAlert({ error: true, message: 'Nieprawidłowe parametry pala' });
            !isValidSaftyFactorsState && setAlert({ error: true, message: 'Nieprawidłowe współczynniki bezpieczeństwa' });

            soilTable.updateState(state => state.map((element, index) => {
                return {
                    ...element,
                    ...invalidSoilState[index]
                }
            }));
            calculations.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(calculationToSend).reduce((acc, [key, value]) => {
                        if(key === 'layersName'){
                            return  { 
                                ...acc, 
                                [key]: value
                            }
                        } else {
                            return { 
                                ...acc, 
                                [key]: { value: value.value, isValid: !!value.isValid } 
                            }
                        }
                    } ,{})
                }
            });
            saftyFactors.updateState(state => {
                return {
                    ...state,
                    ...Object.entries(saftyFactors.state).reduce( (acc, [key, value]) => ({ ...acc, [key]: { value: value.value, isValid: !!value.isValid } }) ,{}),
                }
            })
        }
    }

    const submitCalculation = {
        onSubmit: onSubmitCalculation
    }
    return {
        submitCalculation
    }
}

export default SubmitCalculationHelpers