import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Row } from 'react-bootstrap';
import FirstName from './FirstName';
import LastName from './LastName';
import Password from './Password';
import ConfirmPassword from './ConfirmPassword';
import { AlertMessage2, Email } from '../../components';
import { withForm } from '../../hoc';
import { passwordLength } from '../../config/ProjectConfig/projectPatterns';
import useDeepCompareEffect from 'use-deep-compare-effect';
import logo from '../../assets/Logo.png'
import config from '../../config/config';
import loginImg from '../../assets/login_img.jpg';
import styles from './index.module.css';

const Wrapper = styled.div`
    height: 100vh;
    background-color: #fff;
    font-weight: 700;
    
    & form {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
    }
    & p, label, button, a {
        font-size: 16px !important;
    }
    & label {
        font-weight: 500 !important;
    }
`;
const FormWrapper = styled.div`
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    & .form-group {
        margin: 10px 0;
    }

    & button[type='submit'] {
        width: 100%;
        margin-top: 40px;
        display: block;
        padding: 4px 25px;
        border-radius: 2px;
        transition: all 0.5s ease;

        &:focus {
            box-shadow: none !important;
        }
    }
`;
const H1 = styled.h1`
    font-size: 22px;
    color: #000;
    font-weight: inherit;
    text-align: center;
    margin: 5px 0 35px 0;
`;
const ImageWrapper = styled.div`
    text-align: center;
    margin: 0 0 0px 0;
    padding: 0 20px;
    & img {
        width: 190px
    }
`
const RowWrapper = styled.div`
    margin: 15px 0;
`;
const SignInLink = styled.div`
    text-align: center;
    margin-top: 40px;
    & a {
        color: #666;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        text-align: center;
        text-decoration: underline !important;
        &:hover {
            color: #000;
        }
    }
`;
const TextWithLine = styled.p`
    width: 100%; 
    text-align: center; 
    border-bottom: 2px solid #ddd; 
    line-height: 0;
    margin-bottom: 25px;

    & span {
        background:#fff; 
        padding:0 10px; 
    }
`
const ImageWrapperLogin = styled.div`
    text-align: center;
    margin: 0 0 0px 0;
    padding: 0;
    height: 100%;
    min-height: 100vh;
    
    & img {
        width: 100%;
        height: 100%
    }
`
const signUpState = {
    email: {
        value: '',
        isValid: null,
        occupied: false,
    },
};
const Register = ({
    formState,
    messageState = {},
    onChange,
    actions = {},
    onSubmit,
}) => {
    const { routes } = config;

    const [state, setState] = useState({ ...formState, ...signUpState });

    useEffect(() => {
        document.title = `GeoInCloud | Załóż konto`;

        return () => {
            document.title = '';
        };
    }, []);

    useDeepCompareEffect(() => {
        setState((state) => ({
            ...state,
            ...formState,

            ...(!!formState.clearForm && {
                ...signUpState,
            }),
        }));
    }, [formState]);

    const onConfirmPassword = (e) => {
        let isPasswordCorrect = !!(
            e.target.checkValidity() &&
            e.target.value.length >= passwordLength &&
            e.target.value === state.password.value
        );
        onChange(e, isPasswordCorrect);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        onSubmit(
            (formData) => {
                actions.register({
                    body: formData,
                }); 
            },
            {
                username: {
                    ...state.username,
                },
                email: {
                    ...state.email,
                },
                confirmPassword: {
                    ...state['confirmPassword'],
                    isValid:
                        state.confirmPassword.value.length > 0 &&
                        state.password.value === state.confirmPassword.value,
                },
            },
        );
    };

    return (
        <Wrapper>
            <Row className={styles.rowWrapper}>
                <Col md={6} className={`${styles.colWrapper} ${styles.hideMd}`} >
                    <ImageWrapperLogin>
                        <img src={loginImg} alt='Login'/>
                    </ImageWrapperLogin>
                </Col>
                <Col xs={12} lg={6}>
                    <FormWrapper>
                        <ImageWrapper>
                            <img src={logo} alt='Company logo'/>
                        </ImageWrapper>
                        <Form onSubmit={onSubmitForm} noValidate>
                            <H1>Panel rejestracji</H1>
                            <AlertMessage2
                                {...messageState.register}
                                isLoaded={formState.register.isLoaded}
                                loadingName={formState.register.loadingName}
                            />
                            <RowWrapper>
                                <FirstName
                                    onChange={onChange}
                                    firstName={state.firstName}
                                    autofocus
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <LastName
                                    onChange={onChange}
                                    lastName={state.lastName}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Email
                                    onChange={onChange}
                                    email={state.email}
                                    label="Email"
                                    placeholder="Email"
                                    isCorrectLabel
                                    required
                                />                        
                            </RowWrapper>
                            <RowWrapper>
                                <Password onChange={onChange} password={state.password} />
                            </RowWrapper>
                            <RowWrapper>
                                <ConfirmPassword
                                    onChange={onConfirmPassword}
                                    confirmPassword={state.confirmPassword}
                                />                        
                            </RowWrapper>
                            <RowWrapper>
                                <Button variant="danger" type="submit">
                                    Załóż konto!
                                </Button>                        
                            </RowWrapper>
                            <SignInLink>
                                <TextWithLine><span>lub</span></TextWithLine>
                                <p>Jeżeli masz już konto, <Link to={routes.dashboard.logIn}>Zaloguj się!</Link></p>
                            </SignInLink>
                        </Form>
                    </FormWrapper> 
                </Col>
            </Row>
        </Wrapper>
    );
};

export default withForm({
    component: Register,
    actions: [
        {
            name: 'register',
            url: '/api/auth/register',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            showErrorMessage: true,
            showSuccessMessage: true,
            closeSuccessMessage: true,
            errorMessage: 'Wystąpił błąd podczas zakładania konta',
            successMessage:
                'Dziękujemy za założenie konta, sprawdź skrzynkę pocztową w celu aktywacji konta',
            loadingName: '',
        },
    ],
    localState: {
        firstName: {
            value: '',
            isValid: null,
        },
        email: {
            value: '',
            isValid: null,
        },
        lastName: {
            value: '',
            isValid: null,
        },
        password: {
            value: '',
            isValid: null,
        },
        confirmPassword: {
            value: '',
            isValid: null,
        },
    },
});
