import { useRef } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '../../../components';
import { isNumber, roundNumber } from '../../../utils';
import styles from './index.module.css';
import ChartToPrintReinforcement from '../ChartToPrintReinforcement';

const DefaultReinforcementChart = ({
    chartNames,
    capacityType,
    capacityCollection,
    maxValue,
    chartHeight,
    chartScale,
    settings,

    horAxisRef,
    profileAndValue,
    barWidth,
    chartColor,
    chartColor1,

    report={},
    globalTitle,
}) => {
    const pdfComponentRef = useRef();
    const [currentChartHeight, setCurrentChartHeight] = useState(
        (chartHeight * chartScale) / 100,
    );

    useEffect(() => {
        setCurrentChartHeight((chartHeight * chartScale) / 100);
    }, [chartScale, chartHeight]);

    return (
        <Fragment>
            <div className={styles.pdfWrapper}>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            css="default-button-info-style"
                            title="Drukuj do pdf"
                            name="exportToPdf"
                            icon={faEdit}
                        />
                    )}
                    content={() => pdfComponentRef.current}
                />
            </div>
            <div className={`${styles.chart} backgroundAround`}>
                <div className={styles.title}>
                    <h1>{chartNames[capacityType].title}</h1>
                </div>
                <div className={styles.flex}>
                    <div className={styles.verLabelName}>
                        <p>{chartNames[capacityType].axisY}</p>
                    </div>
                    <div className={styles.chartWrapper}>
                        <div className={styles.verticalAxis}>
                            {capacityCollection.map((ele, idx) => {
                                return (
                                    <div key={idx} className={styles.verticalItem} style={{
                                            top: `${currentChartHeight - (Math.abs(ele)/Math.abs(maxValue))*currentChartHeight}px`,
                                        }}>
                                        <div className={styles.line}></div>
                                        {settings.showVertical && (
                                            <div className={`${styles.verticalAxisValue} backgroundAround`}>
                                                <p>{ele}</p>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div ref={horAxisRef} className={styles.horizontalAxis}>
                            {profileAndValue.map((ele, idx) => {
                                return (
                                    <div key={idx} className={styles.horizontalAxisValue}>
                                        <div className={styles.flexBars}>
                                            {
                                                <div className={styles.flexDown}>
                                                    {settings.showValue && Array.isArray(ele.value) &&
                                                    ele.value.map((element,index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                {
                                                                isNumber(element) ? (
                                                                    <div>
                                                                        <div className={styles.flexEnd} style={{
                                                                                height: `${currentChartHeight - (Math.abs(element)/Math.abs(maxValue))*currentChartHeight}px`,
                                                                            }}>
                                                                            {settings.showValues && (
                                                                                <div className={styles.chartValue}>
                                                                                    <p style={{lineHeight: `${barWidth > 25 ? 25 : barWidth}px`}}>
                                                                                        {roundNumber(element,1)}
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className={styles.value} style={{
                                                                                height: `${(Math.abs(element) / Math.abs(maxValue))*currentChartHeight}px`,
                                                                                width: `${barWidth > 25 ? 25 : barWidth}px`,
                                                                                background: `rgba(${chartColor.r},${chartColor.g},${chartColor.b},${chartColor.a})`,
                                                                            }}>
                                                                            {settings.showDescription && (
                                                                                <p>
                                                                                    {
                                                                                        ele.circular
                                                                                        ?
                                                                                        <Fragment>Śr. zbrojenia, &phi;={ele.rebar}mm</Fragment>
                                                                                        :
                                                                                        <Fragment>Śr. zbrojenia, &phi;={ele.rebarAs1}mm</Fragment>
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className={styles.flexEnd} style={{
                                                                        height: `${currentChartHeight}px`,
                                                                    }}>
                                                                    </div>
                                                                )
                                                                }

                                                            </Fragment>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            {
                                                <div className={styles.flexDown}>
                                                    {settings.showValue1 && Array.isArray(ele.value1) &&

                                                    ele.value1.map((element, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                {
                                                                isNumber(element) ? (
                                                                    <div>
                                                                        <div className={styles.flexEnd} style={{
                                                                                height: `${currentChartHeight - (element/maxValue) *currentChartHeight}px`,
                                                                            }}>
                                                                            {settings.showValues && (
                                                                                <div className={styles.chartValue}>
                                                                                    <p style={{
                                                                                            lineHeight: `${barWidth > 25 ? 25 : barWidth}px`,
                                                                                        }}>
                                                                                        {roundNumber(element,1)}
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className={styles.value} style={{
                                                                                height: `${(element/maxValue) *currentChartHeight}px`,
                                                                                width: `${barWidth > 25 ? 25 : barWidth}px`,
                                                                                background: `rgba(${chartColor1.r},${chartColor1.g},${chartColor1.b},${chartColor1.a})`,
                                                                            }}>
                                                                            {settings.showDescription && (
                                                                                <p>
                                                                                    {
                                                                                        ele.circular
                                                                                        ?
                                                                                        <Fragment>Śr. zbrojenia, &phi;={ele.rebar}mm</Fragment>
                                                                                        :
                                                                                        <Fragment>Śr. zbrojenia, &phi;={ele.rebarAs2}mm</Fragment>
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className={styles.flexEnd} style={{
                                                                        height: `${currentChartHeight}px`,
                                                                    }}></div>
                                                                )                                            
                                                                }
                                                            </Fragment>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        {settings.showHorizontal && (
                                            <div
                                                style={{
                                                    writingMode: `${settings.rotateDescriptionX ? 'vertical-rl' : ''}`,
                                                    transform: `${settings.rotateDescriptionX ? 'rotate(180deg)': ''}`,
                                                    maxHeight: `${settings.rotateDescriptionX ? '150px' : ''}`,
                                                    maxWidth: `${settings.rotateDescriptionX ? '' : '100px'}`,
                                                }}
                                                className={styles.horizontalItem}>
                                                    <p>{ele.profile}</p>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.horLabelName}>
                    <p>{chartNames[capacityType].axisX}</p>
                </div>
                {settings.showLegend && (
                    <div className={styles.legend}>
                        {settings.showValue &&
                            chartNames[capacityType].legend &&
                            Array.isArray(profileAndValue) &&
                            profileAndValue.some((ele) =>
                                Array.isArray(ele.value) && ele.value.some(element => isNumber(element)),
                            ) && (
                                <div className={styles.legendItem}>
                                    <div
                                        style={{
                                            background: `rgba(${chartColor.r},${chartColor.g},${chartColor.b},${chartColor.a})`,
                                        }}
                                        className={styles.pileLength}></div>
                                            <p>{chartNames[capacityType].legend}</p>
                                </div>
                            )}
                        {settings.showValue1 &&
                            chartNames[capacityType].legend1 &&
                            Array.isArray(profileAndValue) &&
                            profileAndValue.some((ele) =>
                                Array.isArray(ele.value1) && ele.value1.some(element => isNumber(element)),
                            ) && (
                                <div className={styles.legendItem}>
                                    <div
                                        style={{
                                            background: `rgba(${chartColor1.r},${chartColor1.g},${chartColor1.b},${chartColor1.a})`,
                                        }}
                                        className={styles.pileLength}></div>
                                    <p>{chartNames[capacityType].legend1}</p>
                                </div>
                            )}
                    </div>
                )}
            </div>

            <ChartToPrintReinforcement
                ref={pdfComponentRef}
                chartNames={chartNames}
                capacityType={capacityType}
                capacityCollection={capacityCollection}
                maxValue={maxValue}
                settings={settings}
                profileAndValue={profileAndValue}
                barWidth={barWidth}
                chartColor={chartColor}
                chartColor1={chartColor1}
                currentChartHeight={currentChartHeight}
                report={report}

                globalTitle={globalTitle}
            />
        </Fragment>
    );
};

export default DefaultReinforcementChart
