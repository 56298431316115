import React, { useState } from 'react';
import { withDefaultPrintChart } from '../../../../../hoc';
import { isNumber, setSoilColorQc, roundNumber } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ApexCharts from "apexcharts";

const QcChart = ({
    results = [],
    chartTitle = '',
    yLabel,
    xLabel, 
    chartOptions,
    setDataURI,
}) => {    
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
        },
    ]);

    useDeepCompareEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...results.sort((a,b) => a.z - b.z)
                        .filter(ele => (isNumber(ele.qc) && isNumber(ele.z)))
                        .reduce((acc, ele, idx, arr) => {
                            if (idx === (arr.length - 1)){
                                return [...acc, [ele.qc, ele.z], [0, ele.z]];
                            } else if(idx === 0) {
                                return [
                                    ...acc, 
                                    [ele.qc, 0], 
                                    [ele.qc, ele.z]
                                ];
                            } else {
                                return [...acc, [ele.qc, ele.z]];
                            }
                        }, []),
                    ],
                },
            ];
        });

        setOptions(() => {
            const maxZ = Math.max(...results.map((ele) => ele.z));
            const maxQc = Math.max(...results.map((ele) => ele.qc));

            return {
                ...chartOptions,
                title: {
                    ...chartOptions['title'],
                    text: chartTitle,
                    offsetY: 30
                },
                yaxis: {
                    ...chartOptions['yaxis'],
                    type: 'numeric',
                    tickAmount: Math.ceil(maxZ) > 30 ? Math.ceil(maxZ)/2 : Math.ceil(maxZ),
                    max: Math.ceil(maxZ),
                    title: {
                        ...chartOptions['yaxis']['title'],
                        text: yLabel,
                        offsetX: -15,
                        style: {
                            fontSize: '12mm'
                        }
                    },
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    type: 'numeric',
                    labels: {
                        ...chartOptions['xaxis']['labels'],
                        offsetY: 45
                    },
                    title: {
                        ...chartOptions['xaxis']['title'],
                        offsetY: -145,
                        text: xLabel,

                        style: {
                            fontSize: '12mm'
                        }
                    },
                    position: 'top',
                    max: Math.ceil(maxQc) + 1,
                    axisBorder: {
                        show: false,
                    },
                },
                dataLabels: {
                    ...chartOptions['dataLabels'],
                    offsetX: 20,
                    formatter: function (x, opt) {
                        const label = opt.w.globals.initialSeries[0].data[opt.dataPointIndex][0];
    
                        return label === 0
                            ? 
                                ''
                            :
                                opt.dataPointIndex % 2 === 0
                                ?
                                roundNumber(opt.w.globals.initialSeries[0].data[opt.dataPointIndex][0],2)
                                :
                                ''
                    },
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#000000'],
                    opacity: 0.35,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    colors: ['#000000'],
                    width: 2,
                },
                grind: {
                    show: false
                },
                annotations: {
                    ...chartOptions['annotations'],
                    position: 'back',
                    yaxis: [
                        ...results.sort((a,b) => a.z-b.z).reduce((acc, ele, idx, arr) => {
                            return [
                                ...acc,
                                {
                                    y: idx === 0 ? 0 : arr[idx - 1].z,
                                    y2: idx === (arr.length - 1) ? Math.ceil(ele.z) : ele.z,
                                    borderColor: 'transparent',
                                    opacity: 0.9,
                                    fillColor: setSoilColorQc({
                                        type: ele.soil,
                                        qc: ele.qc,
                                    }),
                                },
                            ];
                        }, []),
                    ],
                },
            };
        });
    }, [chartOptions, results, chartTitle, xLabel, yLabel]);

    useDeepCompareEffect(() => {
        let chart = new ApexCharts(document.querySelector("#qcChart"), {
            series: series,
            ...options,
            chart: {
                ...options['chart'],
                type: 'area'
            }
        });
        chart.render();

        chart.dataURI().then(({ imgURI }) => {
            setDataURI(imgURI)
        });

        return () => chart.destroy();

    },[series, options]);

    return (
        <div id="qcChart"></div>
    );
};

export default React.memo(
    withDefaultPrintChart(QcChart),
    (prev, next) => stringify(prev) === stringify(next),
);
